import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { CREATE_TAG_GROUP, GET_TAG_GROUPS } from '../../actions/expense/expenseTagGroup'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  tagGroups: [],
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setTagGroups = (data) => (state) => state.setIn(['tagGroups'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function expenseTagGroupReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_TAG_GROUPS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_TAG_GROUPS.SUCCESS:
      return mutate([resetStatus(), setTagGroups(action.payload)])
    case GET_TAG_GROUPS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTagGroups([])])
    case CREATE_TAG_GROUP.DO:
      return mutate([resetStatusSetLoading()])
    case CREATE_TAG_GROUP.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_TAG_GROUP.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    default:
      return state
  }
}
export default expenseTagGroupReducer
