import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class CustomerBalance {
  async getCustomerReports() {
    const response = await get('report', `customer-balance`, 'GET')
    return response
  }

  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default CustomerBalance
