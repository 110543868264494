import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEES,
  UPDATE_EMPLOYEE,
  INIT_SELECTED_EMPLOYEE,
  SET_PAGINATION_PARAMS,
} from '../../actions/sales/salesEmployee'

const generateUniqueNo = () => {
  const timestamp = +new Date()
  return 'EMP' + timestamp.toString().slice(5, timestamp.length)
}
export const DEFAULT_EMPLOYEE = {
  contact_infos: {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    name_on_checks: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    type: 'employee',
  },
  note: '',
  additional_infos: {
    date_of_birth: null,
    employee_no: generateUniqueNo(),
    expense_billing_rate: 0,
    expense_cost_rate: 0,
    gender: null,
    hire_date: null,
    is_default_billable: false,
    release_date: null,
    ssn: null,
  },
  attachments: [],
  addresses: {},
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  employees: [],
  selectedEmployee: DEFAULT_EMPLOYEE,
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setEmployees = (data) => (state) => state.setIn(['employees'], data)
const setSelectedEmployee = (data) => (state) => state.setIn(['selectedEmployee'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setPaginationParams = (params) => (state) => state.mergeIn(['paginationParams'], params)
function salesEmployeeReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_EMPLOYEES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_EMPLOYEES.SUCCESS:
      return mutate([resetStatus(), setEmployees(action.payload)])
    case GET_EMPLOYEES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setEmployees([])])
    case CREATE_EMPLOYEE.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_EMPLOYEE.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_EMPLOYEE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_EMPLOYEE.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_EMPLOYEE.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_EMPLOYEE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_EMPLOYEE.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_EMPLOYEE.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_EMPLOYEE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_EMPLOYEE_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_EMPLOYEE_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedEmployee(action.payload)])
    case GET_EMPLOYEE_BY_ID.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedEmployee(DEFAULT_EMPLOYEE),
      ])
    case SET_PAGINATION_PARAMS:
      return mutate([resetStatusSetLoading(), setPaginationParams(action.payload)])
    case INIT_SELECTED_EMPLOYEE:
      return mutate([resetStatus(), setSelectedEmployee(DEFAULT_EMPLOYEE)])
    default:
      return state
  }
}

export default salesEmployeeReducer
