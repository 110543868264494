import React, { createContext, useContext } from 'react'
import { inject } from 'dependency-injection-es6'
import UserAndGroupService from './UserAndGroupService'
import CustomerService from './CustomerService'
import TaxRateService from './TaxRateService'
import ChartOfAccountService from './ChartOfAccountService'
import GeneralLedgerTransactionService from './GeneralLedgerTransactionService'
import ExpenseService from './ExpenseService'
import ProductService from './ProductService'
import TransactionContactService from './TransactionContactService'
import TagService from './TagService'
import TransactionExpense from './TransactionExpense'
import TransactionCheck from './TransactionCheck'
import TransactionCredit from './TransactionCredit'
import TransactionBill from './TransactionBill'
import TransactionOrder from './TransactionOrder'
import TransactionBillPayment from './TransactionBillPayment'
import TrialReport from './TrialReport'
import ProfitLossReport from './ProfitLossReport'
import BalanceSheet from './BalanceSheet'
import AccountPayableAging from './AccountPayableAging'
import AccountReceivableAging from './AccountReceivableAging'
import InvoiceList from './InvoiceList'
import OpenInvoice from './OpenInvoice'
import Customer from './CustomerBalance'
import Vendor from './VendorBalance'
import SalesByCustomer from './SalesCustomer'
import CustomerTransections from './CustomerTransections'
import SalesByProduct from './SalesProduct'
import UnpaidBill from './UnpaidBill'
import CommonService from './CommonService'

export const GlobalServiceContext = createContext(undefined)

class ServiceGroup {
  @inject(CustomerService) customerService: CustomerService
  @inject(TaxRateService) taxRate: TaxRateService
  @inject(UserAndGroupService) user: UserAndGroupService
  @inject(ChartOfAccountService) chartOfAccount: ChartOfAccountService
  @inject(GeneralLedgerTransactionService) glTransaction: GeneralLedgerTransactionService
  @inject(ExpenseService) expense: ExpenseService
  @inject(ProductService) product: ProductService
  @inject(TransactionContactService) transContact: TransactionContactService
  @inject(TagService) tag: TagService
  @inject(TransactionExpense) transExpense: TransactionExpense
  @inject(TransactionCheck) transCheck: TransactionCheck
  @inject(TransactionCredit) transCredit: TransactionCredit
  @inject(TransactionBill) transBill: TransactionBill
  @inject(TransactionBillPayment) transBillPayment: TransactionBillPayment
  @inject(TransactionOrder) transOrder: TransactionOrder
  @inject(TrialReport) report: TrialReport
  @inject(ProfitLossReport) profitLossReport: ProfitLossReport
  @inject(BalanceSheet) balancesheet: BalanceSheet
  @inject(AccountPayableAging) accountpayableaging: AccountPayableAging
  @inject(AccountReceivableAging) accountreceivableaging: AccountReceivableAging
  @inject(InvoiceList) invoicelist: InvoiceList
  @inject(OpenInvoice) openinvoice: OpenInvoice
  @inject(UnpaidBill) unpaidBill: UnpaidBill
  @inject(Customer) customer: Customer
  @inject(Vendor) vendor: Vendor
  @inject(SalesByCustomer) salescustomer: SalesByCustomer
  @inject(CustomerTransections) customerTransections: CustomerTransections
  @inject(SalesByProduct) salesproduct: SalesByProduct
  @inject(CommonService) commonservice: CommonService
}

const GlobalService = ({ children }) => {
  const handlers = new ServiceGroup()
  return <GlobalServiceContext.Provider value={handlers}>{children}</GlobalServiceContext.Provider>
}

export const useGlobalService = () => {
  const context = useContext(GlobalServiceContext)
  if (context === undefined) {
    throw new Error(
      `GlobalServiceContext was not provided. Make sure your component is child of the GlobalService`,
    )
  }
  return context
}

export default GlobalService
