import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  UPDATE_CURRENCY_ACTION,
  GET_CURRENCY,
  GET_DATE_FORMATE,
  UPDATE_DATE_FORMATE_ACTION,
  GET_NAV,
  UPDATE_COMPANYDETAIL_ACTION,
  GET_COMPANY,
  UPDATE_COMPANYINFO_ACTION,
  UPDATE_COMPANYTYPE_ACTION,
  UPDATE_COMPANYADDRESS_ACTION,
} from '../../actions/accounting/AccountingSettings'
import { LOGIN, LOGOUT } from 'views/user/actions'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  navData: [],
  settings: [],
  advSettings: [],
  companySettings: [],
})
// eslint-disable-next-line no-unused-vars
const setSettings = (data) => (state) => state.setIn(['settings'], data)
const setUpdatedSettings = (data) => (state) => state.setIn(['updateSettings'], data)
const setNavSettings = (data) => (state) => state.setIn(['navData'], data)

const setAdvSettings = (data) => (state) => state.setIn(['advSettings'], data)
const setCompanySettings = (data) => (state) => state.setIn(['companySettings'], data)
const updatedAdvSettings = (data) => (state) => state.setIn(['updateSettings'], data)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
function accountSettings(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CURRENCY.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CURRENCY.SUCCESS:
      return mutate([resetStatus(), setAdvSettings(action.payload)])
    case GET_CURRENCY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setAdvSettings([])])
    case UPDATE_CURRENCY_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_CURRENCY_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case UPDATE_CURRENCY_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case GET_DATE_FORMATE.DO:
      return mutate([resetStatusSetLoading()])
    case GET_DATE_FORMATE.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case GET_DATE_FORMATE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case UPDATE_DATE_FORMATE_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_DATE_FORMATE_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case UPDATE_DATE_FORMATE_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setUpdatedSettings([])])
    case GET_NAV.DO:
      return mutate([resetStatusSetLoading()])
    case GET_NAV.SUCCESS:
      return mutate([resetStatus(), setNavSettings(action.payload)])
    case GET_NAV.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setNavSettings([])])
    case GET_COMPANY.DO:
      return mutate([resetStatusSetLoading()])
    case GET_COMPANY.SUCCESS:
      return mutate([resetStatus(), setCompanySettings(action.payload)])
    case GET_COMPANY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCompanySettings([])])
    case UPDATE_COMPANYDETAIL_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_COMPANYDETAIL_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case UPDATE_COMPANYDETAIL_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case UPDATE_COMPANYINFO_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_COMPANYINFO_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case UPDATE_COMPANYINFO_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case UPDATE_COMPANYTYPE_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case UPDATE_COMPANYTYPE_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_COMPANYTYPE_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    case UPDATE_COMPANYADDRESS_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), updatedAdvSettings([])])
    case UPDATE_COMPANYADDRESS_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case UPDATE_COMPANYADDRESS_ACTION.SUCCESS:
      return mutate([resetStatus(), updatedAdvSettings(action.payload)])
    // Reset user-permissions at login/logout
    case LOGIN.SUCCESS:
    case LOGOUT:
      return mutate([resetStatus(), setNavSettings([])])
    default:
      return state
  }
}

export default accountSettings
