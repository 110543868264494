import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_RULE,
  DELETE_RULE,
  GET_RULE_BY_ID,
  GET_RULES,
  INIT_SELECTED_RULE,
  UPDATE_RULE,
} from '../../actions/banking/bankingRule'

export const DEFAULT_RULE = {
  id: null,
  name: '',
  transaction_direction: 'money_in',
  transaction_type: 'deposit',
  apply_type: 'assign',
  condition_rule_type: 'all',
  memo: '',
  is_memo_to_all: false,
  is_auto_add: false,
  payee_id: null,
  customer_id: null,
  credit_card_id: null,
  bank_account_id: null,
  conditions: [
    {
      type: 'description',
      symbol: 'contains',
      value: '',
    },
  ],
  categories: [
    {
      type: 'percentage',
      value: 100,
      category_id: null,
    },
  ],
  tags: [],
  bank_accounts: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  rules: [],
  selectedRule: DEFAULT_RULE,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setRules = (data) => (state) => state.setIn(['rules'], data)
const setSelectedRule = (data) => (state) => state.setIn(['selectedRule'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function bankingRuleReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_RULES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_RULES.SUCCESS:
      return mutate([resetStatus(), setRules(action.payload)])
    case GET_RULES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setRules([])])
    case CREATE_RULE.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_RULE.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_RULE.FAILED:
      return mutate([setErrors(action.payload)])
    case UPDATE_RULE.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_RULE.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_RULE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_RULE.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_RULE.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_RULE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_RULE_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_RULE_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedRule(action.payload)])
    case GET_RULE_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedRule(DEFAULT_RULE)])
    case INIT_SELECTED_RULE:
      return mutate([resetStatus(), setSelectedRule(action.payload || DEFAULT_RULE)])
    default:
      return state
  }
}

export default bankingRuleReducer
