import apiRoot from '../_data/apiRoot'
import Cookies from 'universal-cookie'

const fileToDataUri = (file) => {
  if (file.size) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader(file)

      reader.onload = function () {
        resolve(reader.result)
      }

      reader.onerror = function () {
        reject(reader.error)
      }

      reader.readAsDataURL(file)
    })
  } else {
    return Promise.resolve('')
  }
}

const base64ToBlob = (base64, mimeType, sliceSize) => {
  mimeType = mimeType || ''
  sliceSize = sliceSize || 512

  const byteCharacters = atob(base64)
  const byteArrays = []
  const byteCharactersSize = byteCharacters.length

  for (let offset = 0; offset < byteCharactersSize; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const sliceSize = slice.length

    const byteNumbers = new Array(sliceSize)

    for (let i = 0; i < sliceSize; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    byteArrays.push(new Uint8Array(byteNumbers))
  }

  return new Blob(byteArrays, { type: mimeType })
}

const dataUriToBlob = (dataUri) => {
  if (dataUri) {
    const c = dataUri.indexOf(',')

    const mime = dataUri.substring(5, dataUri.indexOf(';'))
    const base64Data = dataUri.substr(c + 1)

    return base64ToBlob(base64Data, mime)
  } else {
    return new Blob()
  }
}

const fieldToJson = (name, value) => {
  if (value instanceof Blob) {
    return fileToDataUri(value).then(function (dataUri) {
      return {
        name: name,
        file: value.name || '',
        contents: dataUri,
        isDataUri: true,
      }
    })
  } else {
    return Promise.resolve({
      name: name,
      contents: value,
      isDataUri: false,
    })
  }
}

const formToJson = (form) => {
  const json = []
  let promise = Promise.resolve()

  function addField(name, value) {
    promise = promise.then(function () {
      return fieldToJson(name, value)
    })

    promise = promise.then(function (fieldJson) {
      json.push(fieldJson)

      return Promise.resolve()
    })
  }

  for (let f = 0, leng = form.elements.length; f < leng; f++) {
    const field = form.elements[f]

    if (field.disabled || !field.name) continue

    const tagName = field.nodeName.toLowerCase()
    const name = field.name

    if ('input' === tagName) {
      const type = field.type.toLowerCase()

      if ('checkbox' === type || 'radio' === type) {
        field.checked && addField(name, field.value)
      } else if ('file' === type) {
        for (let i = 0, l = field.files.length; i < l; i++) {
          addField(name, field.files[i])
        }
      } else {
        addField(name, field.value)
      }
    } else if ('select' === tagName) {
      for (let i = 0, l = field.options.length; i < l; i++) {
        if (field.options[i].selected) {
          addField(name, field.options[i].value)
        }
      }
    } else {
      addField(name, field.value)
    }
  }

  promise = promise.then(function () {
    return json
  })

  return promise
}

const formDataToJson = (formData) => {
  const json = []
  let p = Promise.resolve()

  for (let field of formData.entries()) {
    ;((f) => {
      p = p.then(function () {
        return fieldToJson(f[0], f[1])
      })

      p = p.then(function (fieldJson) {
        json.push(fieldJson)
        return Promise.resolve()
      })
    })(field)
  }

  p = p.then(function () {
    return json
  })

  return p
}

const jsonToFormData = (json) => {
  const formData = new FormData()
  for (let field of json) {
    if (field.isDataUri) {
      formData.append(field.name, dataUriToBlob(field.contents), field.file)
    } else {
      formData.append(field.name, field.contents)
    }
  }

  return formData
}

const submit = (formData, action, module) => {
  const cookies = new Cookies()
  const url = `${apiRoot[module]}${action}`
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    xhr.open('POST', url, true)
    const token = cookies.get('token')
    if (token) xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.setRequestHeader('X-Tenant', window.location.host.split('.')[0])

    xhr.onload = function (e) {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr)
      } else {
        const error = JSON.parse(xhr.responseText)
        reject(error)
      }
    }

    xhr.onabort = function (e) {
      const error = new Error('Request Aborted')

      error.code = 1001
      error.xhr = xhr

      reject(error)
    }

    xhr.ontimeout = function (e) {
      const error = new Error('Request Timed Out')

      error.code = 1002
      error.xhr = xhr

      reject(error)
    }

    xhr.onerror = function (e) {
      const error = e.message
      reject(error)
    }

    xhr.send(formData)
  })
}

const formUtils = {
  fileToDataUri,
  base64ToBlob,
  dataUriToBlob,
  fieldToJson,
  formToJson,
  formDataToJson,
  jsonToFormData,
  submit,
}

export default formUtils
