import { createAction, createTypes } from '../../../utils/actions'
import { get, post, destroy, put } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'

export const GET_JOURNAL_ENTRY = createTypes('ZOOM/COA/GET_JOURNAL')

const getJournalRecordAction = {
  do: (params) => createAction(GET_JOURNAL_ENTRY.DO, params),
  success: (accountTypes) => createAction(GET_JOURNAL_ENTRY.SUCCESS, { payload: accountTypes }),
  failed: (errors) => createAction(GET_JOURNAL_ENTRY.FAILED, { payload: errors }),
}

const getLastJournalRecord =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getJournalRecordAction.do())
      const resp = await get('chartOfAccount', `api/last-journal-record`, 'GET')
      dispatch(getJournalRecordAction.success(resp))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      dispatch(getJournalRecordAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const submitJournal = (params) => async (dispatch) => {
  try {
    dispatch(getJournalRecordAction.do())
    const resp = await post(`chartOfAccount`, `api/create-journal-record`, params)
    if (resp.error) {
      dispatch(showErrorToastAction(resp.error))
    } else {
      dispatch(getJournalRecordAction.success(resp))
      dispatch(showSuccessToastAction('Record created successfully'))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting accounts.'
    dispatch(showErrorToastAction(error))
  }
}

const getJournalById = (id) => async (dispatch) => {
  try {
    const resp = await get('chartOfAccount', `api/journal-record/${id}`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice data.'
    return dispatch(showErrorToastAction(error))
  }
}

const deleteJournalRecords = (id) => async (dispatch, getState) => {
  try {
    const resp = await destroy('chartOfAccount', `api/journal-record/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An unknown error occurred.'
    dispatch(showErrorToastAction(error))
    return false
  }
}

const updateJournalById = (params, id) => async (dispatch) => {
  try {
    dispatch(getJournalRecordAction.do())
    const resp = await put(`chartOfAccount`, `api/journal-record/${id}`, params)
    if (resp.error) {
      dispatch(showErrorToastAction(resp.error))
    } else {
      dispatch(getJournalRecordAction.success(resp))
      dispatch(showSuccessToastAction('Record created successfully'))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting accounts.'
    dispatch(showErrorToastAction(error))
  }
}

export {
  getJournalRecordAction,
  getLastJournalRecord,
  submitJournal,
  getJournalById,
  deleteJournalRecords,
  updateJournalById,
}
