import { get } from '../utils/request'

class CustomerService {
  customers = null

  async getCustomers() {
    if (this.customers === null) {
      const response = await get('sales', 'customers?page_length=1000&page=0')
      this.customers = response.data
    }
    return this.customers
  }
}
export default CustomerService
