import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  GET_ACCOUNTS,
  GET_ACCOUNT_TYPES,
  GET_GENERAL_TRANSACTION,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
  GET_GCLOSING_BALANCE,
} from '../../actions/coa/coaAccount'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  accounts: [],
  accountTypes: [],
  generalTransaction: [],
  closingBalance: [],
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setAccounts = (data) => (state) => state.setIn(['accounts'], data)
const setAccountTypes = (data) => (state) => state.setIn(['accountTypes'], data)
const setGeneralTransaction = (data) => (state) => state.setIn(['generalTransaction'], data)
const setClosingBalance = (data) => (state) => state.setIn(['closingBalance'], data)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)

function coaAccountReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_ACCOUNTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_ACCOUNTS.SUCCESS:
      return mutate([resetStatus(), setAccounts(action.payload)])
    case GET_ACCOUNTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setAccounts([])])
    case GET_ACCOUNT_TYPES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_ACCOUNT_TYPES.SUCCESS:
      return mutate([resetStatus(), setAccountTypes(action.payload)])
    case GET_ACCOUNT_TYPES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setAccountTypes([])])
    case GET_GENERAL_TRANSACTION.DO:
      return mutate([resetStatusSetLoading()])
    case GET_GENERAL_TRANSACTION.SUCCESS:
      return mutate([resetStatus(), setGeneralTransaction(action.payload)])
    case GET_GENERAL_TRANSACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setGeneralTransaction([])])
    case GET_GCLOSING_BALANCE.DO:
      return mutate([resetStatusSetLoading()])
    case GET_GCLOSING_BALANCE.SUCCESS:
      return mutate([resetStatus(), setClosingBalance(action.payload)])
    case GET_GCLOSING_BALANCE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setClosingBalance([])])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    default:
      return state
  }
}

export default coaAccountReducer
