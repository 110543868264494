import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { navigateToPage } from '../../../utils/route'
import { getPlansData } from '../../selectors/subscription/subscriptionPlan'

export const CREATE_PLAN = createTypes('ZOOM/SUBSCRIPTION/CREATE_PLAN')
export const GET_PLANS = createTypes('ZOOM/SUBSCRIPTION/GET_PLANS')
export const GET_PLAN_BY_ID = createTypes('ZOOM/SUBSCRIPTION/GET_PLAN_BY_ID')
export const UPDATE_PLAN = createTypes('ZOOM/SUBSCRIPTION/UPDATE_PLAN')
export const DELETE_PLAN = createTypes('ZOOM/SUBSCRIPTION/DELETE_PLAN')
export const INIT_SELECTED_PLAN = createTypes('ZOOM/SUBSCRIPTION/INIT_SELECTED_PLAN')

const createPlanAction = {
  do: (params) => createAction(CREATE_PLAN.DO, params),
  success: () => createAction(CREATE_PLAN.SUCCESS),
  failed: (errors) => createAction(CREATE_PLAN.FAILED, { errors }),
}
const updatePlanAction = {
  do: (params) => createAction(UPDATE_PLAN.DO, params),
  success: () => createAction(UPDATE_PLAN.SUCCESS),
  failed: (errors) => createAction(UPDATE_PLAN.FAILED, { errors }),
}
const getPlansAction = {
  do: (params) => createAction(GET_PLANS.DO, params),
  success: (plans) => createAction(GET_PLANS.SUCCESS, { payload: plans }),
  failed: (errors) => createAction(GET_PLANS.FAILED, { payload: errors }),
}
const getPlanByIdAction = {
  do: (params) => createAction(GET_PLAN_BY_ID.DO, params),
  success: (data) => createAction(GET_PLAN_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_PLAN_BY_ID.FAILED, { errors }),
}
const deletePlanAction = {
  do: () => createAction(DELETE_PLAN.DO),
  success: () => createAction(DELETE_PLAN.SUCCESS),
  failed: (errors) => createAction(DELETE_PLAN.FAILED, { errors }),
}

const initPlanAction = {
  do: () => createAction(INIT_SELECTED_PLAN.DO),
  success: () => createAction(INIT_SELECTED_PLAN.SUCCESS),
  failed: (errors) => createAction(INIT_SELECTED_PLAN.FAILED, { errors }),
}
const getPlans =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getPlansAction.do())
      const resp = await get(
        'subscription',
        `plans?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getPlansAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting plans.'
      dispatch(getPlansAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getPlanById = (id) => async (dispatch) => {
  try {
    dispatch(getPlanByIdAction.do())
    if (id) {
      const resp = await get('subscription', `plans/${id}`, 'GET')
      dispatch(getPlanByIdAction.success(resp))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting plan data.'
    dispatch(getPlanByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const createPlan = (params) => async (dispatch) => {
  try {
    dispatch(createPlanAction.do())
    const resp = await post('subscription', `plans`, params)
    dispatch(createPlanAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
    navigateToPage('/subscription/plans/list')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating plan data.'
    dispatch(createPlanAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const updatePlan = (id, params) => async (dispatch) => {
  try {
    dispatch(updatePlanAction.do())
    const resp = await put('subscription', `plans/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updatePlanAction.success(resp))
    navigateToPage('/subscription/plans/list')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating plan data.'
    dispatch(updatePlanAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deletePlan = (id) => async (dispatch, getState) => {
  try {
    dispatch(deletePlanAction.do())
    await destroy('subscription', `plans/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deletePlanAction.success())
    const plans = [...getPlansData(getState())]
    const index = plans.findIndex((plan) => plan.id === id)
    plans.splice(index, 1)
    dispatch(getPlansAction.success(plans))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating plan data.'
    dispatch(deletePlanAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const initPlan = () => (dispatch) => {
  dispatch(initPlanAction.do())
  dispatch(initPlanAction.success())
}
export {
  createPlanAction,
  updatePlanAction,
  getPlansAction,
  getPlanByIdAction,
  deletePlanAction,
  initPlanAction,
  getPlans,
  getPlanById,
  createPlan,
  updatePlan,
  deletePlan,
  initPlan,
}
