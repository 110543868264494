import { get } from '../utils/request'
import FormUtils from '../utils/form'

export const getProductName = (products, id) => {
  const data = products?.filter((product) => product.id === parseInt(id))[0]
  if (data) return data.name
  return ''
}

class ProductService {
  products = null
  types = [
    { value: 'non_inventory', label: 'Non inventory' },
    { value: 'service', label: 'Service' },
  ]
  initialValues = {
    type: 'non_inventory',
    name: '',
    sku: '',
    product_category_id: '',
    description: '',
    price: 0,
  }
  incomeAccounts = []
  categories = null
  thumbnails = []
  async getProducts(refresh = false) {
    if (this.products === null || refresh) {
      const response = await get('sales', 'products?page_length=1000&page=0')
      this.products = response.data
    }
    return this.products
  }

  async getProductCategories() {
    if (this.categories === null) {
      const response = await get('sales', 'product-categories?page_length=1000&page=0')
      this.categories = response.data.map((category) => ({
        label: category.name,
        value: category.id,
      }))
    }
    return this.categories
  }

  getProductById(id) {
    return this.products.filter((product) => parseInt(product.id) === parseInt(id))[0]
  }
  setThumbnails(thumbnails) {
    this.thumbnails = thumbnails
  }
  async submit(values) {
    const json = []
    Object.keys(values).forEach((key) => {
      json.push({ name: key, contents: key === 'tags' ? JSON.stringify(values[key]) : values[key] })
    })
    this.thumbnails.forEach((thumbnail, key) => {
      json.push({ name: `new_thumbnails[]`, contents: thumbnail })
    })
    const formData = FormUtils.jsonToFormData(json)
    return await FormUtils.submit(formData, 'products', 'sales')
  }
  getTypeLabel(value) {
    return this.types.filter((type) => type.value === value)[0]?.label
  }
  async fetchProductById(id) {
    return await get('sales', `products/${id}`)
  }
}
export default ProductService
