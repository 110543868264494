import 'whatwg-fetch'
import apiRoot from '../_data/apiRoot'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const token = cookies.get('token')

async function checkStatus(response) {
  const json = await response.json()
  let error = {}
  if (!response.ok) {
    // if (response.status === 500 || response.status === 404) {
    //   window.location.href = '/'
    // }
    // error = new Error()
    // if (response.status === 401) {
    //   localStorage.removeItem('token')
    //   if (window.location.href.indexOf('login') === -1) {
    //     window.location.href = '/login'
    //   }
    // }
    if (
      !(
        response.headers.has('Content-Type') &&
        response.headers.get('Content-Type').includes('application/json')
      )
    ) {
      error.body = {
        message: 'Response is not JSON',
      }
    } else {
      if (json.message) error.body = json.message
      if (json.error) error.body = json.error
      if (json.errors) error.body = json.errors
    }
    throw error
  } else {
    return json
  }
}

export default function request(url, options) {
  return fetch(url, options).then(checkStatus)
}

export function get(module, url, method = 'GET', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  headers = Object.assign(headers, defaultHeader)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
}
export function post(module, url, postData, method = 'POST', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  const newHeaders = Object.assign(defaultHeader, headers)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers: newHeaders,
    body: JSON.stringify(postData),
  })
}
export function upload(module, url, formData, headers = {}) {
  let defaultHeader = {
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  const newHeaders = Object.assign(defaultHeader, headers)
  return request(`${apiRoot[module]}${url}`, {
    method: 'POST',
    headers: newHeaders,
    body: formData,
  })
}
export function put(module, url, putData, headers = {}) {
  return post(module, url, putData, 'PUT', headers)
}

export function destroy(module, url, headers = {}) {
  return get(module, url, 'DELETE', headers)
}

export function download(module, url, method = 'GET', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
    responseType: 'blob',
  }
  headers = Object.assign(headers, defaultHeader)

  fetch(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
    .then(function (response) {
      return response.json()
    })
    .then((response) => {
      const linkSource = `data:application/pdf;base64,${response.body}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = response.filename
      downloadLink.click()
    })
}
