import React, { useState } from 'react'
import styled from 'styled-components'
import { CButton } from '@coreui/react-pro'
import { ZIcon } from '../../shared'
import { cilArrowTop } from '@coreui/icons'
const Button = styled(CButton)`
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const BackToTop = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <Button
      onClick={() => scrollToTop()}
      color="zoom"
      style={{ display: visible ? 'inline' : 'none' }}
      shape="rounded-pill"
    >
      <ZIcon icon={cilArrowTop} />
    </Button>
  )
}
export default BackToTop
