import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_CATEGORY,
} from '../../actions/sales/salesProductCategory'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  productCategories: [],
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setProductCategories = (data) => (state) => state.setIn(['productCategories'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function salesProductReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PRODUCT_CATEGORIES.SUCCESS:
      return mutate([resetStatus(), setProductCategories(action.payload)])
    case GET_PRODUCT_CATEGORIES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setProductCategories([])])
    case CREATE_PRODUCT_CATEGORY.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_PRODUCT_CATEGORY.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_PRODUCT_CATEGORY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_PRODUCT_CATEGORY.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_PRODUCT_CATEGORY.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_PRODUCT_CATEGORY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_PRODUCT_CATEGORY.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_PRODUCT_CATEGORY.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_PRODUCT_CATEGORY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    default:
      return state
  }
}
export default salesProductReducer
