import { get } from '../utils/request'

class CustomerTransections {
  async getCustomerTransectionListById(page = 1, page_length = 50, search = '', customerId = 0) {
    return get(
      'sales',
      `contacts/${customerId}/transactions?page=${page}&page_length=${page_length}&search=${search}`,
    )
  }
}
export default CustomerTransections
