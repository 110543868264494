import React from 'react'
import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro'

const Page403 = ({ history }) => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix">
              <h1 className="float-start display-2 me-4 my-4">403</h1>
              <h4 className="pt-3">Access Forbidden</h4>
              <p className="text-medium-emphasis float-start">
                You don't have permission to access this area.
              </p>
              <CButton
                color="primary"
                onClick={() => {
                  history.push('/dashboard')
                }}
              >
                Go back to Dashboard
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Page403
