import { get, post, destroy } from '../utils/request'

class UserAndGroupService {
  module = `user`

  getUserList(page = 1, page_length = 50, search = '') {
    return get(this.module, `api/user?page=${page}&page_length=${page_length}&search=${search}`)
  }

  getUser(userId) {
    return get(this.module, `api/user/${userId}`)
  }

  addUser(data) {
    return post(this.module, `api/user`, data)
  }

  updateUser(id, data) {
    return post(this.module, `api/user/${id}`, data, 'PUT')
  }

  destroyUser(userId) {
    return destroy(this.module, `api/user/${userId}`)
  }

  changeStatus(userId, postData) {
    return post(this.module, `api/user/update-status/${userId}`, postData)
  }

  getUserPermission(userId) {
    return get(this.module, `api/user-permissions?user_id=${userId}`)
  }

  getAllPermissions() {
    return get(this.module, `api/all-permissions`)
  }

  assignRoleAndPermissions(data) {
    return post(this.module, `api/assgin-role-permissions`, data)
  }

  getRoleList(searchText = '') {
    return get(this.module, `api/role?search=${searchText}`)
  }

  addRolePermission(data) {
    return post(this.module, `api/role`, data)
  }

  destroyGroup(groupId) {
    return destroy(this.module, `api/role/${groupId}`)
  }

  getGroup(groupId) {
    return get(this.module, `api/role/${groupId}`)
  }

  updateGroup(id, data) {
    return post(this.module, `api/role/${id}`, data, 'PUT')
  }
}

export default UserAndGroupService
