export const transformForList = (contact) => {
  const contactInfos = {
    title: contact.title,
    first_name: contact.first_name,
    middle_name: contact.middle_name,
    last_name: contact.last_name,
    display_name: contact.display_name,
    name_on_checks: contact.name_on_checks,
    email: contact.email,
    phone_number: contact.phone_number,
    mobile_number: contact.mobile_number,
    type: contact.type,
  }
  const note = contact.notes[0]?.note
  const additionalInfos = contact.add_infos[0] || {}
  const taxRates = contact.tax_rates.map((taxRate) => taxRate.id)
  const attachments = []
  const addresses = {}
  const billingAddress = contact.addresses.filter((address) => address.type === 'billing')[0]
  const shippingAddress = contact.addresses.filter((address) => address.type === 'shipping')[0]
  const locationAddress = contact.addresses.filter((address) => address.type === 'location')[0]

  if (billingAddress) addresses.billing = billingAddress
  if (shippingAddress) addresses.shipping = shippingAddress
  if (locationAddress) addresses.location = locationAddress
  return {
    contact_infos: contactInfos,
    note: note,
    additional_infos: additionalInfos,
    tax_rates: taxRates,
    attachments: attachments,
    addresses: addresses,
    open_balance: contact.open_balance,
    over_due: contact.over_due,
  }
}
