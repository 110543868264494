import { createAction, createTypes } from '../../../utils/actions'
import { get, post } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'

export const GET_RECONCILE_LIST = createTypes('ZOOM/BANKING/GET_RECONCILE_LIST')
export const GET_RECONCILE_INFO = createTypes('ZOOM/BANKING/GET_RECONCILE_INFO')
export const DO_RECONCILE = createTypes('ZOOM/BANKING/DO_RECONCILE')
export const DO_RECONCILE_LATER = createTypes('ZOOM/BANKING/DO_RECONCILE_LATER')
export const SHOW_RECONCILE = 'ZOOM/BANKING/GET_RECONCILE_LIST'
export const SET_RECONCILE_PARAMS = 'ZOOM/BANKING/SET_RECONCILE_PARAMS'
export const getBankingReconcileListAction = {
  do: (params) => createAction(GET_RECONCILE_LIST.DO, params),
  success: (reconcileList) => createAction(GET_RECONCILE_LIST.SUCCESS, { payload: reconcileList }),
  failed: (errors) => createAction(GET_RECONCILE_LIST.FAILED, { errors }),
}
export const getBankingReconcileInfoAction = {
  do: (params) => createAction(GET_RECONCILE_INFO.DO, params),
  success: (reconcileInfo) => createAction(GET_RECONCILE_INFO.SUCCESS, { payload: reconcileInfo }),
  failed: (errors) => createAction(GET_RECONCILE_INFO.FAILED, { errors }),
}
const doReconcileAction = {
  do: (params) => createAction(DO_RECONCILE.DO, params),
  success: () => createAction(DO_RECONCILE.SUCCESS),
  failed: (errors) => createAction(DO_RECONCILE.FAILED, { errors }),
}

const doReconcileLaterAction = {
  do: (params) => createAction(DO_RECONCILE_LATER.DO, params),
  success: () => createAction(DO_RECONCILE_LATER.SUCCESS),
  failed: (errors) => createAction(DO_RECONCILE_LATER.FAILED, { errors }),
}

const getBankingReconcileList = (params) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const urlParams = new URLSearchParams()
      urlParams.append('page', 1)
      urlParams.append('page_length', 1000)
      if (params.coa_id?.value) params.coa_id = params.coa_id.value
      Object.keys(params).forEach((key) => {
        urlParams.append(key, params[key])
      })
      dispatch(getBankingReconcileListAction.do())
      const resp = await get(`banking`, `reconciles?${urlParams.toString()}`, 'GET')
      dispatch(getBankingReconcileListAction.success(resp?.data))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading bank reconciliation data.'
      dispatch(getBankingReconcileListAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const setShowReconcile =
  (show = false) =>
  (dispatch) => {
    dispatch(createAction(SHOW_RECONCILE, { payload: show }))
  }
const setReconcileParams =
  (reconcileParams = {}) =>
  (dispatch) => {
    dispatch(createAction(SET_RECONCILE_PARAMS, { payload: reconcileParams }))
  }
const doReconcile = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(doReconcileAction.do())
      post('banking', 'reconciles', params)
        .then((resp) => {
          dispatch(doReconcileAction.success())
          dispatch(showSuccessToastAction(resp.message))
          resolve()
        })
        .catch((error) => {
          dispatch(showErrorToastAction(error))
          dispatch(doReconcileAction.failed(error))
          reject(error)
        })
    } catch (e) {
      dispatch(showErrorToastAction(e))
      dispatch(doReconcileAction.failed(e))
      reject(e)
    }
  })
}
const doReconcileLater = (ids) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(doReconcileLaterAction.do())
      post('banking', 'reconciles/later', { ids })
        .then((resp) => {
          dispatch(doReconcileLaterAction.success())
          dispatch(showSuccessToastAction(resp.message))
          resolve()
        })
        .catch((error) => {
          dispatch(showErrorToastAction(error))
          dispatch(doReconcileAction.failed(error))
          reject(error)
        })
    } catch (e) {
      dispatch(showErrorToastAction(e))
      dispatch(doReconcileLaterAction.failed(e))
      reject(e)
    }
  })
}
const getBankingReconcileInfo = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(getBankingReconcileInfoAction.do())
      get('banking', `reconciles/get_info?coa_id=${id}`)
        .then((resp) => {
          dispatch(getBankingReconcileInfoAction.success(resp))
          dispatch(showSuccessToastAction(resp.message))
          resolve()
        })
        .catch((error) => {
          dispatch(showErrorToastAction(error))
          dispatch(getBankingReconcileInfoAction.failed(error))
          reject(error)
        })
    } catch (e) {
      dispatch(showErrorToastAction(e))
      dispatch(getBankingReconcileInfoAction.failed(e))
      reject(e)
    }
  })
}
export {
  getBankingReconcileList,
  setShowReconcile,
  setReconcileParams,
  doReconcile,
  doReconcileLater,
  getBankingReconcileInfo,
}
