import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction } from '../global'
import { get, download } from '../../../utils/request'

export const GET_REPORTS = createTypes('ZOOM/TRANSACTION/GET_REPORTS')

const getReportsAction = {
  do: (params) => createAction(GET_REPORTS.DO, params),
  success: (reports) => createAction(GET_REPORTS.SUCCESS, { payload: reports }),
  failed: (errors) => createAction(GET_REPORTS.FAILED, { payload: errors }),
}

const getReports = (params) => async (dispatch) => {
  try {
    dispatch(getReportsAction.do())
    const resp = await get(
      'report',
      `trial-balance?start_date=${params.startDate}&end_date=${params.endDate}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting reports.'
    dispatch(getReportsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const downloadReport = (params) => async (dispatch) => {
  try {
    const resp = await download(
      'report',
      `trial-balance-pdf?start_date=${params.startDate}&end_date=${params.endDate}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting reports.'
    return dispatch(showErrorToastAction(error))
  }
}

export { getReportsAction, downloadReport, getReports }
