import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { get, post } from '../../../utils/request'

export const SET_ADVANCED_DATA_ACCOUNTING = createTypes('ZOOM/SETTINGS/SET_ADVANCED_ACC')

const getAdvancedAccountingAction = {
  do: (params) => createAction(SET_ADVANCED_DATA_ACCOUNTING.DO, params),
  success: (currency) => createAction(SET_ADVANCED_DATA_ACCOUNTING.SUCCESS, { payload: currency }),
  failed: (errors) => createAction(SET_ADVANCED_DATA_ACCOUNTING.FAILED, { payload: errors }),
}

const updateAdvancedAccounting = (postData) => async (dispatch) => {
  try {
    const resp = await post(`user`, 'api/account-settings-advance/accounting', postData)
    showSuccessToastAction('CUrrency has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    return dispatch(showErrorToastAction(error))
  }
}

const getAdvancedAccounting = () => async (dispatch) => {
  try {
    dispatch(getAdvancedAccountingAction.do())
    const resp = await get('user', 'api/account-settings-advance/accounting', 'GET')
    dispatch(getAdvancedAccountingAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoices.'
    dispatch(getAdvancedAccountingAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

export { getAdvancedAccountingAction, updateAdvancedAccounting, getAdvancedAccounting }
