export const selectSalesInvoice = (state) => state.get('salesInvoice')
export const getInvoicesData = (state) => selectSalesInvoice(state).getIn(['invoices'])
export const getCustomerLedgerData = (state) => selectSalesInvoice(state).getIn(['customer_ledger'])
export const getInvoiceTermsData = (state) => selectSalesInvoice(state).getIn(['terms'])
export const getSelectedInvoice = (state) => selectSalesInvoice(state).getIn(['selectedInvoice'])
export const getLatestInvoiceNumberData = (state) =>
  selectSalesInvoice(state).getIn(['latestInvoiceNumber'])
export const getSelectedInvoiceLines = (state) =>
  selectSalesInvoice(state).getIn(['selectedInvoice', 'lines'])
export const getSelectedInvoiceInstallments = (state) =>
  selectSalesInvoice(state).getIn(['selectedInvoice', 'installments'])
export const getInvoiceTypes = (state) =>
  selectSalesInvoice(state)
    .getIn(['types'])
    .toArray()
    .map((item) => item.toObject())
export const getStatusError = (state) => selectSalesInvoice(state).getIn(['status', 'errors'])
export const getSavingStatus = (state) => selectSalesInvoice(state).getIn(['status', 'isSaving'])
export const getLoadingStatus = (state) => selectSalesInvoice(state).getIn(['status', 'isLoading'])
export const getIncomeAccounts = (state) => selectSalesInvoice(state).getIn(['incomeAccounts'])
export const getDeletingStatus = (state) =>
  selectSalesInvoice(state).getIn(['status', 'isDeleting'])
export const getPaginationParams = (state) => {
  return selectSalesInvoice(state).getIn(['paginationParams']).toObject()
}
export const getCountDetailsData = (state) => selectSalesInvoice(state).getIn(['count_details'])
export const getSalesChartData = (state) => selectSalesInvoice(state).getIn(['sales_chart_data'])
