import { get } from '../utils/request'

class TaxRateService {
  taxRates = null

  async getTaxRates() {
    if (this.taxRates === null) {
      const response = await get('sales', 'tax-rates?page_length=100&page=1')
      this.taxRates = response.data
    }
    return this.taxRates
  }
}
export default TaxRateService
