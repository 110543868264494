import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { GET_JOURNAL_ENTRY } from '../../actions/coa/journalEntry'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  accounts: [],
  accountTypes: [],
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setJournalEntry = (data) => (state) => {
  state.setIn(['journalEntry'], data)
}

function coaAccountReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_JOURNAL_ENTRY.DO:
      return mutate([resetStatusSetLoading()])
    case GET_JOURNAL_ENTRY.SUCCESS:
      return mutate([resetStatus(), setJournalEntry(action.payload)])
    case GET_JOURNAL_ENTRY.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setJournalEntry([])])
    default:
      return state
  }
}

export default coaAccountReducer
