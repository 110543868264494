import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { SET_CUSTOMIZATION_SETTING_ACTION } from 'store/actions/accounting/CustomizationSettings'

const DEFAULT_STATUS = {
  errors: null,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
})

const setTransactionNumberSeriesData = (data) => (state) =>
  state.setIn(['customizationSetting'], data)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)

function CustomizationSetting(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case SET_CUSTOMIZATION_SETTING_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case SET_CUSTOMIZATION_SETTING_ACTION.SUCCESS:
      return mutate([resetStatus(), setTransactionNumberSeriesData(action.payload)])
    case SET_CUSTOMIZATION_SETTING_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTransactionNumberSeriesData([])])
    default:
      return state
  }
}

export default CustomizationSetting
