import { createAction, createTypes } from '../../../utils/actions'

const GET_COMPANY_SETTINGS = createTypes('ZOOM/SETTINGS/GET_COMPANY_SETTINGS')
const UPDATE_COMPANY_SETTINGS = createTypes('ZOOM/SETTINGS/GET_COMPANY_SETTINGS')

const getCompanySettingsAction = {
  do: () => createAction(GET_COMPANY_SETTINGS.DO),
  success: (settings) => createAction(GET_COMPANY_SETTINGS.SUCCESS, { payload: settings }),
  failed: (errors) => createAction(GET_COMPANY_SETTINGS.FAILED, { errors }),
}
const updateCompanySettingsAction = {
  do: (params) => createAction(UPDATE_COMPANY_SETTINGS.DO, params),
  success: () => createAction(UPDATE_COMPANY_SETTINGS.SUCCESS),
  failed: (errors) => createAction(UPDATE_COMPANY_SETTINGS.FAILED, { errors }),
}

export {
  GET_COMPANY_SETTINGS,
  UPDATE_COMPANY_SETTINGS,
  getCompanySettingsAction,
  updateCompanySettingsAction,
}
