import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put, download } from '../../../utils/request'

export const GET_CHECKS = createTypes('ZOOM/TRANSACTION/GET_CHECKS')
export const CREATE_CHECK = createTypes('ZOOM/TRANSACTION/CREATE_CHECK')
export const COPY_CHECK = createTypes('ZOOM/TRANSACTION/COPY_CHECK')
export const GET_CHECK_BY_ID = createTypes('ZOOM/TRANSACTION/GET_CHECK_BY_ID')
export const DELETE_CHECK = createTypes('ZOOM/TRANSACTION/DELETE_CHECK')
export const VOID_CHECK = createTypes('ZOOM/TRANSACTION/VOID_CHECK')
export const UPDATE_CHECK = createTypes('ZOOM/TRANSACTION/UPDATE_CHECK')
export const INIT_SELECTED_CHECK = 'ZOOM/TRANSACTION/INIT_SELECTED_CHECK'

const getChecksAction = {
  do: (params) => createAction(GET_CHECKS.DO, params),
  success: (checks) => createAction(GET_CHECKS.SUCCESS, { payload: checks }),
  failed: (errors) => createAction(GET_CHECKS.FAILED, { payload: errors }),
}

const createCheckAction = {
  do: (params) => createAction(CREATE_CHECK.DO, params),
  success: () => createAction(CREATE_CHECK.SUCCESS),
  failed: (errors) => createAction(CREATE_CHECK.FAILED, { errors }),
}

const copyCheckAction = {
  do: (params) => createAction(COPY_CHECK.DO, params),
  success: () => createAction(COPY_CHECK.SUCCESS),
  failed: (errors) => createAction(COPY_CHECK.FAILED, { errors }),
}

const getCheckByIdAction = {
  do: (params) => createAction(GET_CHECK_BY_ID.DO, params),
  success: (data) => createAction(GET_CHECK_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_CHECK_BY_ID.FAILED, { errors }),
}

const deleteCheckAction = {
  do: () => createAction(DELETE_CHECK.DO),
  success: () => createAction(DELETE_CHECK.SUCCESS),
  failed: (errors) => createAction(DELETE_CHECK.FAILED, { errors }),
}

const voidCheckAction = {
  do: () => createAction(VOID_CHECK.DO),
  success: () => createAction(VOID_CHECK.SUCCESS),
  failed: (errors) => createAction(VOID_CHECK.FAILED, { errors }),
}

const updateCheckAction = {
  do: (params) => createAction(UPDATE_CHECK.DO, params),
  success: () => createAction(UPDATE_CHECK.SUCCESS),
  failed: (errors) => createAction(UPDATE_CHECK.FAILED, { errors }),
}

const getChecks =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getChecksAction.do())
      const resp = await get('expense', `checks?page=${pageStart}&page_length=${pageLength}`, 'GET')
      dispatch(getChecksAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting checks.'
      dispatch(getChecksAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createCheck = (params) => async (dispatch) => {
  try {
    dispatch(createCheckAction.do())
    const resp = await post('expense', `checks`, params)
    dispatch(createCheckAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating check data.'
    dispatch(createCheckAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyCheck = (params) => async (dispatch) => {
  try {
    dispatch(copyCheckAction.do())
    const resp = await post('expense', `copy-check`, params)
    dispatch(copyCheckAction.success(resp))
    dispatch(showSuccessToastAction('Successfully Copy.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while copy check data.'
    dispatch(copyCheckAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getCheckById = (id) => async (dispatch) => {
  try {
    dispatch(getCheckByIdAction.do())
    if (id) {
      const resp = await get('expense', `checks/${id}`, 'GET')
      var checkData = resp
      checkData.categories = resp.transaction.tran_list
      checkData.items = resp.transaction.tran_item
      checkData.tags = resp.transaction.tran_tag
      checkData.attachment = resp?.attachment ? resp?.attachment : []
      dispatch(getCheckByIdAction.success(checkData))
    } else {
      await dispatch(createAction(INIT_SELECTED_CHECK))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting check data.'
    dispatch(getCheckByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteCheck = (id) => async (dispatch) => {
  try {
    dispatch(deleteCheckAction.do())
    const resp = await destroy('expense', `checks/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteCheckAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting check data.'
    dispatch(deleteCheckAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const voidCheck = (id) => async (dispatch) => {
  try {
    dispatch(voidCheckAction.do())
    const resp = await get('expense', `void-check/${id}`, 'GET')
    dispatch(showSuccessToastAction('Successfully voided.'))
    dispatch(voidCheckAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while voiding check data.'
    dispatch(voidCheckAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateCheck = (id, params) => async (dispatch) => {
  try {
    dispatch(updateCheckAction.do())
    const resp = await put('expense', `checks/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateCheckAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating check data.'
    dispatch(updateCheckAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const generateCheckNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-check-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest check number.'
  }
}

const getCheckPDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await download('expense', `checks/${id}/get-check-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting check pdf.'
    return dispatch(showErrorToastAction(error))
  }
}
export {
  getChecksAction,
  createCheckAction,
  copyCheckAction,
  getCheckByIdAction,
  deleteCheckAction,
  voidCheckAction,
  updateCheckAction,
  getChecks,
  createCheck,
  copyCheck,
  getCheckById,
  deleteCheck,
  voidCheck,
  updateCheck,
  generateCheckNumber,
  getCheckPDFUrl,
}
