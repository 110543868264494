import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'

export const GET_INVOICE_LISTS = createTypes('ZOOM/TRANSACTION/GET_INVOICE_LISTS')

const getInvoiceReportsAction = {
  do: (params) => createAction(GET_INVOICE_LISTS.DO, params),
  success: (InvoiceList) => createAction(GET_INVOICE_LISTS.SUCCESS, { payload: InvoiceList }),
  failed: (errors) => createAction(GET_INVOICE_LISTS.FAILED, { payload: errors }),
}

const getInvoiceReports = (params) => async (dispatch) => {
  try {
    dispatch(getInvoiceReportsAction.do())
    const resp = await get(
      'report',
      `invoice-list?start_date=${params.startDate}&end_date=${params.endDate}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice list reports.'
    dispatch(getInvoiceReportsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

export { getInvoiceReportsAction, getInvoiceReports }
