import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { createAction, createTypes } from '../../../utils/actions'

export const CREATE_INVENTORY_ADJUSTMENT = createTypes(
  'ZOOM/SALES/CREATE_PRODUCT_INVENTORY_ADJUSTMENT',
)
export const UPDATE_INVENTORY_ADJUSTMENT = createTypes(
  'ZOOM/SALES/UPDATE_PRODUCT_INVENTORY_ADJUSTMENT',
)
export const DELETE_INVENTORY_ADJUSTMENT = createTypes(
  'ZOOM/SALES/DELETE_PRODUCT_INVENTORY_ADJUSTMENT',
)
export const GET_INVENTORY_ADJUSTMENTS = createTypes('ZOOM/SALES/GET_INVENTORY_ADJUSTMENTS')
export const GET_INVENTORY_ADJUSTMENT_BY_ID = createTypes(
  'ZOOM/SALES/GET_PRODUCT_INVENTORY_ADJUSTMENT_BY_ID',
)
export const INIT_SELECTED_INVENTORY_ADJUSTMENT = 'ZOOM/SALES/INIT_SELECTED_INVENTORY_ADJUSTMENT'
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_INVENTORY_ADJUSTMENT_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_INVENTORY_ADJUSTMENT_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_INVENTORY_ADJUSTMENT_TOTAL_SIZE'

const createProductInventoryAdjustmentAction = {
  do: (params) => createAction(CREATE_INVENTORY_ADJUSTMENT.DO, params),
  success: () => createAction(CREATE_INVENTORY_ADJUSTMENT.SUCCESS),
  failed: (errors) => createAction(CREATE_INVENTORY_ADJUSTMENT.FAILED, { errors }),
}

const updateProductInventoryAdjustmentAction = {
  do: (params) => createAction(UPDATE_INVENTORY_ADJUSTMENT.DO, params),
  success: () => createAction(UPDATE_INVENTORY_ADJUSTMENT.SUCCESS),
  failed: (errors) => createAction(UPDATE_INVENTORY_ADJUSTMENT.FAILED, { errors }),
}

const getInventoryAdjustmentsAction = {
  do: (params) => createAction(GET_INVENTORY_ADJUSTMENTS.DO, params),
  success: (products) => createAction(GET_INVENTORY_ADJUSTMENTS.SUCCESS, { payload: products }),
  failed: (errors) => createAction(GET_INVENTORY_ADJUSTMENTS.FAILED, { payload: errors }),
}

const getInventoryAdjustmentByIdAction = {
  do: (params) => createAction(GET_INVENTORY_ADJUSTMENT_BY_ID.DO, params),
  success: (data) => createAction(GET_INVENTORY_ADJUSTMENT_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_INVENTORY_ADJUSTMENT_BY_ID.FAILED, { errors }),
}

const deleteProductInventoryAdjustmentAction = {
  do: () => createAction(DELETE_INVENTORY_ADJUSTMENT.DO),
  success: () => createAction(DELETE_INVENTORY_ADJUSTMENT.SUCCESS),
  failed: (errors) => createAction(DELETE_INVENTORY_ADJUSTMENT.FAILED, { errors }),
}

const initSelectedInventoryAdjustment =
  (product = {}) =>
  (dispatch) => {
    dispatch(createAction(INIT_SELECTED_INVENTORY_ADJUSTMENT, { payload: product }))
  }
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })

const getLatestInventoryAdjustmentNumber = () => async (dispatch) => {
  try {
    const resp = await get('sales', 'product/latest-inventory-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest inventory number'
    return dispatch(showErrorToastAction(error))
  }
}

const getProductInventoryAdjustmentById = (id) => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(getInventoryAdjustmentByIdAction.do())
      if (id) {
        const resp = await get('sales', `product/inventory-adjustment/${id}`, 'GET')
        dispatch(getInventoryAdjustmentByIdAction.success(resp))
        return resolve(resp)
      } else {
        await dispatch(createAction(INIT_SELECTED_INVENTORY_ADJUSTMENT))
      }
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting inventory adjustment'
      dispatch(getInventoryAdjustmentByIdAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  })
}

const getInventoryAdjustments =
  (pageStart = 0, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      dispatch(getInventoryAdjustmentsAction.do())
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `product/inventory-adjustment?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      dispatch(setTotalSize(resp.total))
      dispatch(getInventoryAdjustmentsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting inventory adjustment.'
      dispatch(getInventoryAdjustmentsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getProductInventoryAdjustmentDataArray =
  (pageStart = 0, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `product/inventory-adjustment?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting inventory adjustments.'
      return dispatch(showErrorToastAction(error))
    }
  }

const createProductInventoryAdjustment = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createProductInventoryAdjustmentAction.do())
      const resp = await post('sales', 'product/inventory-adjustment', params, 'POST')
      dispatch(showSuccessToastAction('Successfully adjusted inventory.'))
      dispatch(createProductInventoryAdjustmentAction.success(resp))
      dispatch(getInventoryAdjustments())
      return resolve(resp)
    } catch (e) {
      const error = e?.body ? e.body : 'An error occurred while creating inventory adjustment'
      dispatch(createProductInventoryAdjustmentAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const updateProductInventoryAdjustment = (id, params) => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(updateProductInventoryAdjustmentAction.do())
      const resp = await put('sales', `product/inventory-adjustment/${id}`, params)
      dispatch(showSuccessToastAction('Successfully updated.'))
      dispatch(updateProductInventoryAdjustmentAction.success(resp))
      dispatch(getInventoryAdjustments())
      return resolve(resp)
    } catch (e) {
      const error = e?.body ? e.body : 'An error occurred while updating inventory adjustment.'
      dispatch(updateProductInventoryAdjustmentAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const deleteProductInventoryAdjustment = (id) => async (dispatch) => {
  try {
    dispatch(deleteProductInventoryAdjustmentAction.do())
    const resp = await destroy('sales', `product/inventory-adjustment/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteProductInventoryAdjustmentAction.success(resp))
    dispatch(getInventoryAdjustments())
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting inventory adjustment.'
    dispatch(deleteProductInventoryAdjustmentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

export {
  initSelectedInventoryAdjustment,
  getLatestInventoryAdjustmentNumber,
  getInventoryAdjustments,
  getProductInventoryAdjustmentDataArray,
  createProductInventoryAdjustment,
  getProductInventoryAdjustmentById,
  updateProductInventoryAdjustment,
  deleteProductInventoryAdjustment,
}
