import { v4 as uuidv4 } from 'uuid'

const generateInvoiceNo = () => {
  return uuidv4()
}
const generateTrackingNo = () => {
  return uuidv4()
}
const generateReferenceNo = () => {
  return uuidv4()
}
const generateUniqueNo = () => {
  return uuidv4()
}

export { generateTrackingNo, generateReferenceNo, generateInvoiceNo, generateUniqueNo }
