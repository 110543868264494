import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'

export const GET_BILLPAYMENTS = createTypes('ZOOM/TRANSACTION/GET_BILLPAYMENTS')
export const CREATE_BILLPAYMENT = createTypes('ZOOM/TRANSACTION/CREATE_BILLPAYMENT')
export const GET_BILLPAYMENT_BY_ID = createTypes('ZOOM/TRANSACTION/GET_BILLPAYMENT_BY_ID')
export const DELETE_BILLPAYMENT = createTypes('ZOOM/TRANSACTION/DELETE_BILLPAYMENT')
export const VOID_BILLPAYMENT = createTypes('ZOOM/TRANSACTION/VOID_BILLPAYMENT')
export const UPDATE_BILLPAYMENT = createTypes('ZOOM/TRANSACTION/UPDATE_BILLPAYMENT')
export const INIT_SELECTED_BILLPAYMENT = 'ZOOM/TRANSACTION/INIT_SELECTED_BILLPAYMENT'

const getBillPaymentsAction = {
  do: (params) => createAction(GET_BILLPAYMENTS.DO, params),
  success: (billpayment) => createAction(GET_BILLPAYMENTS.SUCCESS, { payload: billpayment }),
  failed: (errors) => createAction(GET_BILLPAYMENTS.FAILED, { payload: errors }),
}

const createBillPaymentAction = {
  do: (params) => createAction(CREATE_BILLPAYMENT.DO, params),
  success: () => createAction(CREATE_BILLPAYMENT.SUCCESS),
  failed: (errors) => createAction(CREATE_BILLPAYMENT.FAILED, { errors }),
}

const getBillPaymentByIdAction = {
  do: (params) => createAction(GET_BILLPAYMENT_BY_ID.DO, params),
  success: (data) => createAction(GET_BILLPAYMENT_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_BILLPAYMENT_BY_ID.FAILED, { errors }),
}

const deleteBillPaymentAction = {
  do: () => createAction(DELETE_BILLPAYMENT.DO),
  success: () => createAction(DELETE_BILLPAYMENT.SUCCESS),
  failed: (errors) => createAction(DELETE_BILLPAYMENT.FAILED, { errors }),
}

const voidBillPaymentAction = {
  do: () => createAction(VOID_BILLPAYMENT.DO),
  success: () => createAction(VOID_BILLPAYMENT.SUCCESS),
  failed: (errors) => createAction(VOID_BILLPAYMENT.FAILED, { errors }),
}

const updateBillPaymentAction = {
  do: (params) => createAction(UPDATE_BILLPAYMENT.DO, params),
  success: () => createAction(UPDATE_BILLPAYMENT.SUCCESS),
  failed: (errors) => createAction(UPDATE_BILLPAYMENT.FAILED, { errors }),
}

const getBillPayments =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getBillPaymentsAction.do())
      const resp = await get(
        'expense',
        `bill-payments?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getBillPaymentsAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting bill payment.'
      dispatch(getBillPaymentsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createBillPayment = (params) => async (dispatch) => {
  try {
    dispatch(createBillPaymentAction.do())
    const resp = await post('expense', `bill-payments`, params)
    dispatch(createBillPaymentAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating bill payment data.'
    dispatch(createBillPaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getBillPaymentById = (id) => async (dispatch) => {
  try {
    dispatch(getBillPaymentByIdAction.do())
    if (id) {
      const resp = await get('expense', `bill-payments/${id}`, 'GET')
      var billPaymentData = resp.expenses[0]
      billPaymentData.errors = ''
      billPaymentData.expense_list = resp.expense_list
      billPaymentData.expense_tag = resp.expense_tag
      dispatch(getBillPaymentByIdAction.success(billPaymentData))
    } else {
      await dispatch(createAction(INIT_SELECTED_BILLPAYMENT))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting bill payment data.'
    dispatch(getBillPaymentByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteBillPayment = (id) => async (dispatch) => {
  try {
    dispatch(deleteBillPaymentAction.do())
    const resp = await destroy('expense', `bill-payments/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteBillPaymentAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting bill payment data.'
    dispatch(deleteBillPaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const voidBillPayment = (id) => async (dispatch) => {
  try {
    dispatch(voidBillPaymentAction.do())
    const resp = await get('expense', `void-bill-payment/${id}`, 'GET')
    dispatch(showSuccessToastAction('Successfully voided.'))
    dispatch(voidBillPaymentAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while voiding bill payment data.'
    dispatch(voidBillPaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateBillPayment = (id, params) => async (dispatch) => {
  try {
    dispatch(updateBillPaymentAction.do())
    const resp = await put('expense', `bill-payments/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateBillPaymentAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating bill payment data.'
    dispatch(updateBillPaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const generateBillPayNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-billpayment-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest invoice number.'
  }
}

const getBillPaymentDataById = (id) => async (dispatch) => {
  try {
    const resp = await get('expense', `bill-payments/${id}`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Bill Payment Data.'
    return dispatch(showErrorToastAction(error))
  }
}

export {
  getBillPaymentsAction,
  createBillPaymentAction,
  getBillPaymentByIdAction,
  deleteBillPaymentAction,
  voidBillPaymentAction,
  updateBillPaymentAction,
  getBillPayments,
  createBillPayment,
  getBillPaymentById,
  deleteBillPayment,
  voidBillPayment,
  updateBillPayment,
  generateBillPayNumber,
  getBillPaymentDataById,
}
