import { createAction, createTypes } from '../../../utils/actions'
import { get } from '../../../utils/request'
import { showErrorToastAction } from '../global'

export const GET_PAYMENT_METHODS = createTypes('ZOOM/SALES/GET_PAYMENT_METHODS')
export const SET_PAGINATION_PARAMS = 'ZOOM/SALES/SET_PAYMENT_METHOD_PAGINATION_PARAMS'
export const setPaginationParams = (params) =>
  createAction(SET_PAGINATION_PARAMS, { payload: params })

const getPaymentMethodsAction = {
  do: (params) => createAction(GET_PAYMENT_METHODS.DO, params),
  success: (paymentMethods) =>
    createAction(GET_PAYMENT_METHODS.SUCCESS, { payload: paymentMethods }),
  failed: (errors) => createAction(GET_PAYMENT_METHODS.FAILED, { payload: errors }),
}
const getPaymentMethods =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getPaymentMethodsAction.do())
      const resp = await get(
        'sales',
        `payment-methods?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(
        setPaginationParams({
          page: resp.current_page,
          sizePerPage: parseInt(resp.per_page),
          totalSize: resp.total,
        }),
      )
      dispatch(getPaymentMethodsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting payment methods.'
      dispatch(getPaymentMethodsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
export { getPaymentMethods }
