import styled from 'styled-components'

export default styled.div`
  .form-label {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
  }
`
