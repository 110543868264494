import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put, download } from '../../../utils/request'

export const GET_CREDITS = createTypes('ZOOM/TRANSACTION/GET_CREDITS')
export const CREATE_CREDIT = createTypes('ZOOM/TRANSACTION/CREATE_CREDIT')
export const COPY_CREDIT = createTypes('ZOOM/TRANSACTION/COPY_CREDIT')
export const GET_CREDIT_BY_ID = createTypes('ZOOM/TRANSACTION/GET_CREDIT_BY_ID')
export const DELETE_CREDIT = createTypes('ZOOM/TRANSACTION/DELETE_CREDIT')
export const UPDATE_CREDIT = createTypes('ZOOM/TRANSACTION/UPDATE_CREDIT')
export const INIT_SELECTED_CREDIT = 'ZOOM/TRANSACTION/INIT_SELECTED_CREDIT'

const getCreditsAction = {
  do: (params) => createAction(GET_CREDITS.DO, params),
  success: (credits) => createAction(GET_CREDITS.SUCCESS, { payload: credits }),
  failed: (errors) => createAction(GET_CREDITS.FAILED, { payload: errors }),
}

const createCreditAction = {
  do: (params) => createAction(CREATE_CREDIT.DO, params),
  success: () => createAction(CREATE_CREDIT.SUCCESS),
  failed: (errors) => createAction(CREATE_CREDIT.FAILED, { errors }),
}

const copyCreditAction = {
  do: (params) => createAction(COPY_CREDIT.DO, params),
  success: () => createAction(COPY_CREDIT.SUCCESS),
  failed: (errors) => createAction(COPY_CREDIT.FAILED, { errors }),
}

const getCreditByIdAction = {
  do: (params) => createAction(GET_CREDIT_BY_ID.DO, params),
  success: (data) => createAction(GET_CREDIT_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_CREDIT_BY_ID.FAILED, { errors }),
}

const deleteCreditAction = {
  do: () => createAction(DELETE_CREDIT.DO),
  success: () => createAction(DELETE_CREDIT.SUCCESS),
  failed: (errors) => createAction(DELETE_CREDIT.FAILED, { errors }),
}

const updateCreditAction = {
  do: (params) => createAction(UPDATE_CREDIT.DO, params),
  success: () => createAction(UPDATE_CREDIT.SUCCESS),
  failed: (errors) => createAction(UPDATE_CREDIT.FAILED, { errors }),
}

const getCredits =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getCreditsAction.do())
      const resp = await get(
        'expense',
        `credits?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getCreditsAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting credits.'
      dispatch(getCreditsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createCredit = (params) => async (dispatch) => {
  try {
    dispatch(createCreditAction.do())
    const resp = await post('expense', `credits`, params)
    dispatch(createCreditAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating credit data.'
    dispatch(createCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyCredit = (params) => async (dispatch) => {
  try {
    dispatch(copyCreditAction.do())
    const resp = await post('expense', `copy-credit`, params)
    dispatch(copyCreditAction.success(resp))
    dispatch(showSuccessToastAction('Successfully Copy.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while copy credit data.'
    dispatch(copyCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getCreditById = (id) => async (dispatch) => {
  try {
    dispatch(getCreditByIdAction.do())
    if (id) {
      const resp = await get('expense', `credits/${id}`, 'GET')
      var creditData = resp
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      creditData.categories = resp.transaction.tran_list
      creditData.items = resp.transaction.tran_item
      creditData.tags = resp.transaction.tran_tag
      creditData.attachment = resp?.attachment ? resp?.attachment : []
      dispatch(getCreditByIdAction.success({ ...creditData, addresses: addresses }))
    } else {
      await dispatch(createAction(INIT_SELECTED_CREDIT))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting credit data.'
    dispatch(getCreditByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteCredit = (id) => async (dispatch) => {
  try {
    dispatch(deleteCreditAction.do())
    const resp = await destroy('expense', `credits/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteCreditAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating credit data.'
    dispatch(deleteCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateCredit = (id, params) => async (dispatch) => {
  try {
    dispatch(updateCreditAction.do())
    const resp = await put('expense', `credits/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateCreditAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating credit data.'
    dispatch(updateCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const removeAttachment = (id) => async (dispatch) => {
  try {
    await destroy('expense', `credits/${id}/remove-attachment`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting invoice attachment.'
    dispatch(showErrorToastAction(error))
  }
}
const generateCreditNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-credit-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest invoice number.'
  }
}

const getVendorCreditPDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await download('expense', `credits/${id}/get-credits-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Vendor Credit pdf.'
    return dispatch(showErrorToastAction(error))
  }
}

export {
  getCreditsAction,
  createCreditAction,
  copyCreditAction,
  getCreditByIdAction,
  deleteCreditAction,
  updateCreditAction,
  getCredits,
  createCredit,
  copyCredit,
  getCreditById,
  deleteCredit,
  updateCredit,
  removeAttachment,
  generateCreditNumber,
  getVendorCreditPDFUrl,
}
