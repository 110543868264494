import {
  generateInvoiceNo,
  generateReferenceNo,
  generateTrackingNo,
  generateUniqueNo,
} from '../utils/sales'
import { inject } from 'dependency-injection-es6'
import ProductService from './ProductService'

class ExpenseService {
  @inject(ProductService) product: ProductService
  invoices = null
  terms = null
  termsByID = null
  initialValues = {
    term_id: '',
    invoice_no: generateInvoiceNo(),
    reference_no: generateReferenceNo(),
    tracking_no: generateTrackingNo(),
    tags: [],
    billing_address: null,
    shipping_address: null,
    location_address: null,
    payment_date: '',
    invoice_date: '',
    due_date: '',
    shipping_date: '',
    ship_via: null,
    lines: [],
    message_on_invoice: '',
    message_on_statement: '',
  }
  tags = []
  lines = []

  addExpenseLine(line) {
    this.lines.push(line)
  }
  getLines() {
    return this.lines
  }
  validate(values) {
    const errors = {}
    if (!values.customer_id) errors.customer_id = 'Customer is required.'
    if (!values.term_id) errors.term_id = 'Term is required.'
    if (!values.payment_date) errors.payment_date = 'Payment date is required.'
    if (!values.invoice_date) errors.invoice_date = 'Invoice date is required.'
    if (!values.shipping_date) errors.shipping_date = 'Shipping date is required.'
    if (!values.due_date) errors.due_date = 'Due date is required.'
    if (!values.tags || values.tags.length === 0) errors.tags = 'Tags are required.'
    if (!values.billing_address) errors.billing_address = 'Billing address is required.'
    if (!values.shipping_address) errors.shipping_address = 'Shipping address is required.'
    if (!values.location_address) errors.location_address = 'Location address is required.'
    if (!values.message_on_invoice) errors.message_on_invoice = 'Text is required.'
    if (!values.message_on_statement) errors.message_on_statement = 'Text is required.'
    if (this.lines.length === 0) errors.lines = 'Lines are required.'
    return errors
  }
  getSubTotal(lines = null) {
    let isProductNotFound = false
    if (!lines) {
      lines = this.getLines()
    }
    let value = 0
    for (let line of lines) {
      const productItem = this.product.getProductById(line.product_id)
      if (!productItem) {
        isProductNotFound = true
        break
      } else {
        const price = productItem ? productItem.price : 0
        value += price * line.quantity
      }
    }
    return isProductNotFound ? 'Product Not Found' : value
  }
  async submit(values) {
    values['lines'] = this.getLines()
    values['addresses'] = [
      { ...values['location_address'], type: 'location' },
      { ...values['shipping_address'], type: 'shipping' },
      { ...values['ship_via'], type: 'ship_via' },
      { ...values['billing_address'], type: 'billing' },
    ]
    values['unique_no'] = generateUniqueNo()
    // return await post('sales', 'invoices', values)
  }
}

export default ExpenseService
