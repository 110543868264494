import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  INIT_SELECTED_CUSTOMER,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
} from '../../actions/sales/salesCustomer'

export const DEFAULT_CUSTOMER = {
  contact_infos: {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    name_on_checks: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    type: 'customer',
  },
  note: '',
  additional_infos: {
    parent_id: '',
    customer_type: '',
    suffix: '',
    fax: '',
    website: '',
    other: '',
    is_tax_exempt: false,
    exemption_id: '',
    exemption_details: '',
    opening_balance: '',
    as_of_balance: '',
    gst_treatment: 'Unregistered Business',
    tax_number: '',
    delivery_method: 'none',
  },
  tax_rates: [],
  attachments: [],
  addresses: {},
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  customers: [],
  selectedCustomer: DEFAULT_CUSTOMER,
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setCustomers = (data) => (state) => state.setIn(['customers'], data)
const setSelectedCustomer = (data) => (state) => state.setIn(['selectedCustomer'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)
function salesCustomerReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CUSTOMERS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CUSTOMERS.SUCCESS:
      return mutate([resetStatus(), setCustomers(action.payload)])
    case GET_CUSTOMERS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCustomers([])])
    case CREATE_CUSTOMER.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_CUSTOMER.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_CUSTOMER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_CUSTOMER.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_CUSTOMER.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_CUSTOMER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_CUSTOMER.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_CUSTOMER.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_CUSTOMER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_CUSTOMER_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CUSTOMER_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedCustomer(action.payload)])
    case GET_CUSTOMER_BY_ID.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedCustomer(DEFAULT_CUSTOMER),
      ])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    case INIT_SELECTED_CUSTOMER:
      return mutate([resetStatus(), setSelectedCustomer(DEFAULT_CUSTOMER)])
    default:
      return state
  }
}

export default salesCustomerReducer
