import { createAction, createTypes } from '../../../utils/actions'
import { destroy, get, post, put } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { getApiKeysData } from '../../selectors/settings/settingIntegration'

export const GET_API_KEYS = createTypes('ZOOM/SETTINGS/INTEGRATION/GET_API_KEYS')
export const CREATE_API_KEY = createTypes('ZOOM/SETTINGS/INTEGRATION/CREATE_API_KEY')
export const DELETE_API_KEY = createTypes('ZOOM/SETTINGS/INTEGRATION/DELETE_API_KEY')
export const UPDATE_API_KEY = createTypes('ZOOM/SETTINGS/INTEGRATION/UPDATE_API_KEY')

const getApiKeysAction = {
  do: () => createAction(GET_API_KEYS.DO),
  success: (apiKeys) => createAction(GET_API_KEYS.SUCCESS, { payload: apiKeys }),
  failed: (errors) => createAction(GET_API_KEYS.FAILED, { errors }),
}
const createApiKeyAction = {
  do: () => createAction(CREATE_API_KEY.DO),
  success: () => createAction(CREATE_API_KEY.SUCCESS),
  failed: (errors) => createAction(CREATE_API_KEY.FAILED, { errors }),
}
const updateApiKeyAction = {
  do: () => createAction(UPDATE_API_KEY.DO),
  success: () => createAction(UPDATE_API_KEY.SUCCESS),
  failed: (errors) => createAction(UPDATE_API_KEY.FAILED, { errors }),
}
const deleteApiKeyAction = {
  do: () => createAction(DELETE_API_KEY.DO),
  success: () => createAction(DELETE_API_KEY.SUCCESS),
  failed: (errors) => createAction(DELETE_API_KEY.FAILED, { errors }),
}

const getApiKeys =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getApiKeysAction.do())
      // Required in future
      // const resp = await get(
      //   'settings',
      //   `api-keys?page=${pageStart}&page_length=${pageLength}`,
      //   'GET',
      // )
      // dispatch(getApiKeysAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading api keys.'
      dispatch(getApiKeysAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createApiKey = (params) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createApiKeyAction.do())
      const resp = await post('settings', `api-keys`, params)
      dispatch(createApiKeyAction.success(resp))
      const apiKeys = [...getApiKeysData(getState())]
      apiKeys.push(resp)
      dispatch(getApiKeysAction.success(apiKeys))
      dispatch(showSuccessToastAction('Successfully created.'))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while creating api key.'
      dispatch(createApiKeyAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}
const updateApiKey = (id, params) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(updateApiKeyAction.do())
      const resp = await put('settings', `api-keys/${id}`, params)
      dispatch(updateApiKeyAction.success(resp))
      const apiKeys = [...getApiKeysData(getState())].map((apiKey) => {
        if (apiKey.id === id) {
          apiKey = resp
        }
        return apiKey
      })
      dispatch(getApiKeysAction.success(apiKeys))
      dispatch(showSuccessToastAction('Successfully updated.'))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while updating api key.'
      dispatch(getApiKeysAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const deleteApiKey = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteApiKeyAction.do())
    const resp = await destroy('settings', `api-keys/${id}`)
    dispatch(deleteApiKeyAction.success(resp))
    const apiKeys = [...getApiKeysData(getState())].filter((apiKey) => apiKey.id !== id)
    dispatch(getApiKeysAction.success(apiKeys))
    dispatch(showSuccessToastAction('Successfully deleted.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting api key.'
    dispatch(deleteApiKeyAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

export { getApiKeys, createApiKey, updateApiKey, deleteApiKey }
