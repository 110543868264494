import { fromJS } from 'immutable'

import pipeMutators from 'utils/mutator'
import { REHYDRATE } from 'redux-persist'
import { LOCATION_CHANGE } from 'react-router-redux'
import {
  LOGIN,
  REGISTER,
  LOGOUT,
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY,
  GET_ADV,
  GET_PROFILE,
} from './actions'

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetError = () => (state) =>
  state.setIn(['status', 'error'], null).setIn(['status', 'tokenError'], null)
const resetUser = () => (state) => state.mergeIn(['user'], DEFAULT_USER)
const resetToken = () => (state) => state.mergeIn(['token'], DEFAULT_TOKEN)
const resetPasswordResetToken = () => (state) => state.setIn(['passwordResetToken'], '')

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'loading'], true)

const resetStatusSetTokenLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'tokenLoading'], true)

const resetStatusSetError = (error) => (state) =>
  resetStatus()(state).setIn(['status', 'error'], error || {})

const resetStatusSetLinkSent = () => (state) =>
  resetStatus()(state).setIn(['status', 'passwordResetLinkSent'], true)

const resetStatusSetPasswordReset = () => (state) =>
  resetStatus()(state).mergeIn(['status', 'passwordReset'], true)

const passwordChangeSuccess = () => (state) =>
  resetStatus()(state).setIn(['status', 'passwordChanged'], true)

const setUser = (email, id, first_name, last_name, domain, email_verified_at) => (state) =>
  state.mergeIn(['user'], {
    email,
    id,
    first_name,
    last_name,
    domain,
    email_verified_at,
  })

const setToken =
  (token, ttl = 10000000000000) =>
  (state) =>
    state.mergeIn(['token'], {
      id: token,
      ttl,
      validUntil: Date.now() + ttl,
    })

const setPasswordResetToken = (token) => (state) => state.setIn(['passwordResetToken'], token)

const DEFAULT_STATUS = {
  loading: false,
  error: null,
  tokenLoading: false,
  tokenError: null,
  passwordResetLinkSent: false,
  passwordReset: false,
  passwordChanged: false,
}

const DEFAULT_USER = {
  email: '',
  id: '',
  first_name: '',
  last_name: '',
  domain: '',
  email_verified_at: '',
}
const DEFAULT_TOKEN = {
  id: '',
  ttl: '',
  validUntil: false,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  user: DEFAULT_USER,
  token: DEFAULT_TOKEN,
  passwordResetToken: '',
})

const setUserSettings = (data) => (state) => {
  state.setIn(['userSettings'], data)
}
const setAdvSettings = (data) => (state) => state.setIn(['advSettings'], data)
function authReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case REHYDRATE:
      if (action.payload.auth) return resetStatus()(action.payload.auth)
      return state
    case LOCATION_CHANGE:
      return mutate(resetError())
    case LOGIN.DO:
      return mutate([resetStatusSetLoading(), resetUser(), resetToken()])
    case REGISTER.DO:
      return mutate([resetStatusSetLoading(), resetUser(), resetToken()])
    case VALIDATE_TOKEN.DO:
      return mutate([resetStatusSetTokenLoading(), resetUser(), resetToken()])
    case VERIFY.SUCCESS:
      return state.mergeIn(['user'], { email_verified_at: action.email_verified_at })
    case LOGIN.SUCCESS:
    case REGISTER.SUCCESS:
    case VALIDATE_TOKEN.SUCCESS:
      return mutate([
        resetStatus(),
        setUser(
          action.email,
          action.id,
          action.first_name,
          action.last_name,
          action.domain,
          action.email_verified_at,
        ),
        setToken(action.token),
      ])
    case LOGIN.FAILED:
    case REGISTER.FAILED:
      return mutate([resetStatusSetError(action.error), resetUser(), resetToken()])
    case VERIFY.FAILED:
      return state.mergeIn(['user'], { email_verified_at: null })
    case LOGOUT:
      return mutate([resetStatus(), resetUser(), resetToken()])
    case PASSWORD_RESET_LINK.DO:
      return mutate(resetStatusSetLoading())
    case PASSWORD_RESET_LINK.SUCCESS:
      return mutate(resetStatusSetLinkSent())
    case PASSWORD_RESET_LINK.FAILED:
      return mutate(resetStatusSetError(action.error))

    case VERIFY_PASSWORD_TOKEN.DO:
      return mutate([resetStatusSetLoading(), resetPasswordResetToken()])
    case VERIFY_PASSWORD_TOKEN.SUCCESS:
      return mutate([resetStatus(), setPasswordResetToken(action.token)])
    case VERIFY_PASSWORD_TOKEN.FAILED:
      return mutate([resetStatusSetError(action.error), resetPasswordResetToken()])

    case RESET_PASSWORD.DO:
      return mutate(resetStatusSetLoading())
    case RESET_PASSWORD.SUCCESS:
      return mutate(resetStatusSetPasswordReset())
    case RESET_PASSWORD.FAILED:
      return mutate(resetStatusSetError(action.error))

    case CHANGE_PASSWORD.DO:
      return mutate(resetStatusSetLoading())
    case CHANGE_PASSWORD.SUCCESS:
      return mutate(passwordChangeSuccess())
    case CHANGE_PASSWORD.FAILED:
      return mutate(resetStatusSetError(action.error))
    case GET_PROFILE.SUCCESS:
      return mutate(setUserSettings(action.payload))
    case GET_ADV.SUCCESS:
      return mutate(setAdvSettings(action.payload))
    default:
      return state
  }
}

export default authReducer
