import { createAction, createTypes } from '../../../utils/actions'
import { get } from '../../../utils/request'
import { showErrorToastAction } from '../global'

export const GET_COUNTRIES = createTypes('ZOOM/SALES/GET_COUNTRIES')
export const GET_STATES = createTypes('ZOOM/SALES/GET_STATES')
export const GET_SHIPPING_STATES = createTypes('ZOOM/SALES/GET_SHIPPING_STATES')
export const GET_TIMEZONES = createTypes('ZOOM/SALES/GET_TIMEZONES')
const getCountryAction = {
  do: (params) => createAction(GET_COUNTRIES.DO, params),
  success: (countries) => createAction(GET_COUNTRIES.SUCCESS, { payload: countries }),
  failed: (errors) => createAction(GET_COUNTRIES.FAILED, { payload: errors }),
}
const getStateAction = {
  do: (params) => createAction(GET_STATES.DO, params),
  success: (states) => createAction(GET_STATES.SUCCESS, { payload: states }),
  failed: (errors) => createAction(GET_STATES.FAILED, { payload: errors }),
}
const getShippingStateAction = {
  do: (params) => createAction(GET_SHIPPING_STATES.DO, params),
  success: (shippingStates) =>
    createAction(GET_SHIPPING_STATES.SUCCESS, { payload: shippingStates }),
  failed: (errors) => createAction(GET_SHIPPING_STATES.FAILED, { payload: errors }),
}
const getTimezonesAction = {
  do: () => createAction(GET_TIMEZONES.DO),
  success: (data) => createAction(GET_TIMEZONES.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_TIMEZONES.FAILED, { errors }),
}
const getCountryList =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getCountryAction.do())
      const resp = await get(
        'sales',
        `countries?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      let data = []
      if (Array.isArray(resp.data)) {
        data = resp.data.map((item) => ({
          value: item.iso_code,
          label: item.name,
        }))
      }
      dispatch(getCountryAction.success(data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting customer types.'
      dispatch(getCountryAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getStateList = (id, target) => async (dispatch) => {
  try {
    target === 'billing' ? dispatch(getStateAction.do()) : dispatch(getShippingStateAction.do())
    const resp = await get('sales', `state/${id}`, 'GET')
    let data = []
    if (Array.isArray(resp)) {
      data = resp.map((item) => ({
        value: item.state_code,
        label: `${item.state_name}`,
      }))
    }
    target === 'billing'
      ? dispatch(getStateAction.success(data))
      : dispatch(getShippingStateAction.success(data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting customer types.'
    dispatch(getStateAction.failed(error))(target === 'billing')
      ? dispatch(getStateAction.failed(error))
      : dispatch(getShippingStateAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getTimeZoneList =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getTimezonesAction.do())
      const resp = await get(
        `sales`,
        `timezones?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getTimezonesAction.success(resp))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while updating sales setting'
      return dispatch(showErrorToastAction(error))
    }
  }
export { getCountryList, getStateList, getTimeZoneList }
