import { post, get } from '../utils/request'

class GeneralLedgerTransactionService {
  module = `chartOfAccount`

  create(data) {
    return post(this.module, `api/general-ledger-transaction`, data)
  }

  list(page = 0, page_length = 1000, search = '') {
    return get(
      this.module,
      `api/general-ledger-transaction?page=${page}&page_length=${page_length}&search=${encodeURIComponent(
        search,
      )}`,
    )
  }

  voidTransaction(transactionNumber, data) {
    return post(this.module, `api/general-ledger-transaction/void/${transactionNumber}`, data)
  }

  get(transactionNumber) {
    return get(this.module, `api/general-ledger-transaction/${transactionNumber}`)
  }

  update(transactionNumber, data) {
    return post(this.module, `api/general-ledger-transaction/${transactionNumber}`, data, 'PUT')
  }
}

export default GeneralLedgerTransactionService
