import { assocPath } from 'ramda'
import { fromJS } from 'immutable'

export function clearReducers(baseReducer, currentState, currentAction, { except = [[]] } = {}) {
  let persistedState = {}
  except.forEach((path) => {
    const persistedValue = currentState.getIn(path)
    persistedState = assocPath(path, persistedValue, persistedState)
  })
  return baseReducer(fromJS(persistedState), currentAction)
}
