import { get } from '../utils/request'

class CommonService {
  countries = null
  states = null

  async getCountries() {
    if (this.countries === null) {
      const response = await get('sales', 'countries?page_length=1000&page=1')
      this.countries = response.data
    }
    return this.countries
  }

  async getStates(id) {
    if (this.states === null) {
      const response = await get('sales', `state/${id}`)
      this.states = response.data
    }
    return this.states
  }
}
export default CommonService
