import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { getProductCategoriesData } from '../../selectors/sales/salesProductCategory'

export const GET_PRODUCT_CATEGORIES = createTypes('ZOOM/SALES/GET_PRODUCT_CATEGORIES')
export const CREATE_PRODUCT_CATEGORY = createTypes('ZOOM/SALES/CREATE_PRODUCT_CATEGORY')
export const UPDATE_PRODUCT_CATEGORY = createTypes('ZOOM/SALES/UPDATE_PRODUCT_CATEGORY')
export const DELETE_PRODUCT_CATEGORY = createTypes('ZOOM/SALES/DELETE_PRODUCT_CATEGORY')
const getProductCategoriesAction = {
  do: (params) => createAction(GET_PRODUCT_CATEGORIES.DO, params),
  success: (productCategories) =>
    createAction(GET_PRODUCT_CATEGORIES.SUCCESS, { payload: productCategories }),
  failed: (errors) => createAction(GET_PRODUCT_CATEGORIES.FAILED, { payload: errors }),
}

const createProductCategoryAction = {
  do: () => createAction(CREATE_PRODUCT_CATEGORY.DO),
  success: () => createAction(CREATE_PRODUCT_CATEGORY.SUCCESS),
  failed: (errors) => createAction(CREATE_PRODUCT_CATEGORY.FAILED, { errors }),
}
const updateProductCategoryAction = {
  do: () => createAction(UPDATE_PRODUCT_CATEGORY.DO),
  success: () => createAction(UPDATE_PRODUCT_CATEGORY.SUCCESS),
  failed: (errors) => createAction(UPDATE_PRODUCT_CATEGORY.FAILED, { errors }),
}
const deleteProductCategoryAction = {
  do: () => createAction(DELETE_PRODUCT_CATEGORY.DO),
  success: () => createAction(DELETE_PRODUCT_CATEGORY.SUCCESS),
  failed: (errors) => createAction(DELETE_PRODUCT_CATEGORY.FAILED, { errors }),
}
const getProductCategories =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getProductCategoriesAction.do())
      const resp = await get(
        'sales',
        `product-categories?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getProductCategoriesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting product categories.'
      dispatch(getProductCategoriesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const allProductCategories =
  (pageStart = 1, pageLength = 10, filter) =>
  async (dispatch) => {
    try {
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `product-categories?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      dispatch(showErrorToastAction(error))
      return false
    }
  }
const createProductCategory = (params) => async (dispatch) => {
  try {
    dispatch(createProductCategoryAction.do())
    await post('sales', `product-categories`, params)
    dispatch(createProductCategoryAction.success())
    dispatch(getProductCategories(0, 10000))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating product category.'
    dispatch(createProductCategoryAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const updateProductCategory = (id, params) => async (dispatch, getState) => {
  try {
    dispatch(updateProductCategoryAction.do())
    const resp = await put('sales', `product-categories/${id}`, params)
    const newProductCategories = [...getProductCategoriesData(getState())].map(
      (productCategory) => {
        if (productCategory.id === id) {
          productCategory.name = params.name
        }
        return productCategory
      },
    )
    dispatch(getProductCategoriesAction.success(newProductCategories))
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateProductCategoryAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating product category.'
    dispatch(updateProductCategoryAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteProductCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteProductCategoryAction.do())
    const resp = await destroy('sales', `product-categories/${id}`)
    const newProductCategories = [...getProductCategoriesData(getState())]
    const index = newProductCategories.findIndex((plan) => plan.id === id)
    newProductCategories.splice(index, 1)
    dispatch(getProductCategoriesAction.success(newProductCategories))
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteProductCategoryAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating product-categories.'
    dispatch(deleteProductCategoryAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
export {
  getProductCategories,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
  allProductCategories,
}
