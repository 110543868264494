import React from 'react'
import { CCardTitle } from '@coreui/react-pro'
import styled from 'styled-components'

const Wrapper = styled.div`
  .card-title p {
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: black;
    text-align: left;
  }

  .card-title span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
`

const ZCardTitle = (props) => {
  return (
    <Wrapper>
      <CCardTitle {...props}>
        <p>{props.title}</p>
        {props.subtitle && <span>{props.subtitle}</span>}
      </CCardTitle>
    </Wrapper>
  )
}
export default ZCardTitle
