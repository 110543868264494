const applyFunction = (state, fn) => fn(state)

const pipeMutators = (originalState) => (mutators) =>
  originalState.withMutations((state) => {
    if (Array.isArray(mutators)) {
      mutators.reduce(applyFunction, state)
    } else {
      mutators(state)
    }
  })

export default pipeMutators
