import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import moment from 'moment'
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS,
  INIT_SELECTED_PRODUCT,
  SET_PRODUCT_BUNDLES,
  UPDATE_PRODUCT,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
} from '../../actions/sales/salesProduct'

export const DEFAULT_PRODUCT = {
  id: null,
  unit_id: null,
  type: 'inventory',
  name: '',
  sku: '',
  product_category_id: 0,
  description: '',
  price: '',
  cost_price: '',
  tags: [],
  thumbnails: [],
  bundles: [],
  display_on_transaction: false,
  tax_category_id: 0,
  tax_rates: [],
  hsn_code_master_id: [],
  as_of_date: moment().format('YYYY-MM-DD'),
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  selectedProduct: DEFAULT_PRODUCT,
  products: [],
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setProducts = (data) => (state) => state.setIn(['products'], data)
const setSelectedProduct = (data) => (state) => state.setIn(['selectedProduct'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setSelectedProductBundles = (bundles) => (state) =>
  state.setIn(['selectedProduct', 'bundles'], bundles)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)

function salesProductReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PRODUCTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PRODUCTS.SUCCESS:
      return mutate([resetStatus(), setProducts(action.payload)])
    case GET_PRODUCTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setProducts([])])
    case CREATE_PRODUCT.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_PRODUCT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_PRODUCT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_PRODUCT.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_PRODUCT.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_PRODUCT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_PRODUCT.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_PRODUCT.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_PRODUCT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_PRODUCT_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PRODUCT_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedProduct(action.payload)])
    case GET_PRODUCT_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedProduct(DEFAULT_PRODUCT)])
    case SET_PRODUCT_BUNDLES:
      return mutate([setSelectedProductBundles(action.payload)])
    case INIT_SELECTED_PRODUCT:
      return mutate([resetStatus(), setSelectedProduct(action.payload || DEFAULT_PRODUCT)])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    default:
      return state
  }
}
export default salesProductReducer
