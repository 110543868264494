import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { GET_PAYMENT_METHODS, SET_PAGINATION_PARAMS } from '../../actions/sales/salesPaymentMethod'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  paymentMethods: [],
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setPaymentMethods = (data) => (state) => state.setIn(['paymentMethods'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setPaginationParams = (params) => (state) => state.mergeIn(['paginationParams'], params)
function salesPaymentMethodReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PAYMENT_METHODS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PAYMENT_METHODS.SUCCESS:
      return mutate([resetStatus(), setPaymentMethods(action.payload)])
    case GET_PAYMENT_METHODS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setPaymentMethods([])])
    case SET_PAGINATION_PARAMS:
      return mutate([resetStatusSetLoading(), setPaginationParams(action.payload)])
    default:
      return state
  }
}
export default salesPaymentMethodReducer
