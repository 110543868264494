import { createAction, createTypes } from '../../../utils/actions'
import { destroy, get, post, put } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { getCustomerTypesData } from '../../selectors/sales/salesCustomerType'

export const GET_CUSTOMER_TYPES = createTypes('ZOOM/SALES/GET_CUSTOMER_TYPES')
export const CREATE_CUSTOMER_TYPE = createTypes('ZOOM/SALES/CREATE_CUSTOMER_TYPE')
export const UPDATE_CUSTOMER_TYPE = createTypes('ZOOM/SALES/UPDATE_CUSTOMER_TYPE')
export const DELETE_CUSTOMER_TYPE = createTypes('ZOOM/SALES/DELETE_CUSTOMER_TYPE')
const getCustomerTypesAction = {
  do: (params) => createAction(GET_CUSTOMER_TYPES.DO, params),
  success: (customerTypes) => createAction(GET_CUSTOMER_TYPES.SUCCESS, { payload: customerTypes }),
  failed: (errors) => createAction(GET_CUSTOMER_TYPES.FAILED, { payload: errors }),
}

const createCustomerTypeAction = {
  do: () => createAction(CREATE_CUSTOMER_TYPE.DO),
  success: () => createAction(CREATE_CUSTOMER_TYPE.SUCCESS),
  failed: (errors) => createAction(CREATE_CUSTOMER_TYPE.FAILED, { errors }),
}
const updateCustomerTypeAction = {
  do: () => createAction(UPDATE_CUSTOMER_TYPE.DO),
  success: () => createAction(UPDATE_CUSTOMER_TYPE.SUCCESS),
  failed: (errors) => createAction(UPDATE_CUSTOMER_TYPE.FAILED, { errors }),
}
const deleteCustomerTypeAction = {
  do: () => createAction(DELETE_CUSTOMER_TYPE.DO),
  success: () => createAction(DELETE_CUSTOMER_TYPE.SUCCESS),
  failed: (errors) => createAction(DELETE_CUSTOMER_TYPE.FAILED, { errors }),
}
const getCustomerTypes =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getCustomerTypesAction.do())
      const resp = await get(
        'sales',
        `customer-types?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getCustomerTypesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting customer types.'
      dispatch(getCustomerTypesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const allCustomerType =
  (pageStart = 1, pageLength = 10, filter) =>
  async (dispatch) => {
    try {
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `customer-types?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      dispatch(showErrorToastAction(error))
      return false
    }
  }
const createCustomerType = (params) => async (dispatch) => {
  try {
    dispatch(createCustomerTypeAction.do())
    const response = await post('sales', `customer-types`, params)
    dispatch(createCustomerTypeAction.success())
    dispatch(getCustomerTypes(0, 10000))
    dispatch(showSuccessToastAction('Successfully created.'))
    return response
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating customer type.'
    dispatch(createCustomerTypeAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}
const updateCustomerType = (id, params) => async (dispatch, getState) => {
  try {
    dispatch(updateCustomerTypeAction.do())
    const resp = await put('sales', `customer-types/${id}`, params)
    const newCustomerTypes = [...getCustomerTypesData(getState())].map((customerType) => {
      if (customerType.value === id) {
        customerType.label = params.name
      }
      return customerType
    })
    dispatch(getCustomerTypesAction.success(newCustomerTypes))
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateCustomerTypeAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating customer type.'
    dispatch(updateCustomerTypeAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteCustomerType = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteCustomerTypeAction.do())
    const resp = await destroy('sales', `customer-types/${id}`)
    const newCustomerTypes = [...getCustomerTypesData(getState())]
    const index = newCustomerTypes.findIndex((customerType) => customerType.value === id)
    newCustomerTypes.splice(index, 1)
    dispatch(getCustomerTypesAction.success(newCustomerTypes))
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteCustomerTypeAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating customer type.'
    dispatch(deleteCustomerTypeAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
export {
  getCustomerTypes,
  createCustomerType,
  updateCustomerType,
  deleteCustomerType,
  allCustomerType,
}
