export const selectSubscriptionPlan = (state) => state.get('subscriptionPlan')
export const getPlansData = (state) => selectSubscriptionPlan(state).getIn(['plans'])
export const getSelectedPlan = (state) => selectSubscriptionPlan(state).getIn(['selectedPlan'])
export const getStatusError = (state) => selectSubscriptionPlan(state).getIn(['status', 'errors'])
export const getSavingStatus = (state) =>
  selectSubscriptionPlan(state).getIn(['status', 'isSaving'])
export const getLoadingStatus = (state) =>
  selectSubscriptionPlan(state).getIn(['status', 'isLoading'])
export const getDeletingStatus = (state) =>
  selectSubscriptionPlan(state).getIn(['status', 'isDeleting'])
