import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import moment from 'moment'
import {
  CREATE_INVENTORY_ADJUSTMENT,
  DELETE_INVENTORY_ADJUSTMENT,
  GET_INVENTORY_ADJUSTMENT_BY_ID,
  GET_INVENTORY_ADJUSTMENTS,
  UPDATE_INVENTORY_ADJUSTMENT,
} from 'store/actions/sales/salesInventoryAdjustment'
import { INIT_SELECTED_PRODUCT } from 'store/actions/sales/salesProduct'
import {
  SET_CURRENT_PAGE,
  SET_TOTAL_SIZE,
  SET_SIZE_PER_PAGE,
} from 'store/actions/sales/salesInventoryAdjustment'

export const DEFAULT_INVENTORY_ADJUSTMENT = {
  adjustment_type: 'by_quantity',
  ref_no: null,
  adjustment_date: moment().format('YYYY-MM-DD'),
  adjustment_account: null,
  reason: null,
  memo: null,
  items: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  selectedInventoryAdjustment: DEFAULT_INVENTORY_ADJUSTMENT,
  inventoryAdjustments: [],
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const setSelectedInventoryAdjustment = (data) => (state) =>
  state.setIn(['selectedInventoryAdjustment'], data)
const setInventoryAdjustment = (data) => (state) => state.setIn(['inventoryAdjustments'], data)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)

function salesInventoryAdjustmentReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_INVENTORY_ADJUSTMENTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_INVENTORY_ADJUSTMENTS.SUCCESS:
      return mutate([resetStatus(), setInventoryAdjustment(action.payload)])
    case GET_INVENTORY_ADJUSTMENTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setInventoryAdjustment([])])
    case CREATE_INVENTORY_ADJUSTMENT.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_INVENTORY_ADJUSTMENT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_INVENTORY_ADJUSTMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_INVENTORY_ADJUSTMENT.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_INVENTORY_ADJUSTMENT.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_INVENTORY_ADJUSTMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_INVENTORY_ADJUSTMENT.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_INVENTORY_ADJUSTMENT.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_INVENTORY_ADJUSTMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_INVENTORY_ADJUSTMENT_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_INVENTORY_ADJUSTMENT_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedInventoryAdjustment(action.payload)])
    case GET_INVENTORY_ADJUSTMENT_BY_ID.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedInventoryAdjustment(DEFAULT_INVENTORY_ADJUSTMENT),
      ])
    case INIT_SELECTED_PRODUCT:
      return mutate([
        resetStatus(),
        setSelectedInventoryAdjustment(action.payload || DEFAULT_INVENTORY_ADJUSTMENT),
      ])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    default:
      return state
  }
}
export default salesInventoryAdjustmentReducer
