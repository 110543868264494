import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, download, get, post, put } from '../../../utils/request'

export const GET_ORDERS = createTypes('ZOOM/TRANSACTION/GET_ORDERS')
export const CREATE_ORDER = createTypes('ZOOM/TRANSACTION/CREATE_ORDER')
export const COPY_ORDER = createTypes('ZOOM/TRANSACTION/COPY_ORDER')
export const GET_ORDER_BY_ID = createTypes('ZOOM/TRANSACTION/GET_ORDER_BY_ID')
export const DELETE_ORDER = createTypes('ZOOM/TRANSACTION/DELETE_ORDER')
export const UPDATE_ORDER = createTypes('ZOOM/TRANSACTION/UPDATE_ORDER')
export const INIT_SELECTED_ORDER = 'ZOOM/TRANSACTION/INIT_SELECTED_ORDER'

const getOrdersAction = {
  do: (params) => createAction(GET_ORDERS.DO, params),
  success: (orders) => createAction(GET_ORDERS.SUCCESS, { payload: orders }),
  failed: (errors) => createAction(GET_ORDERS.FAILED, { payload: errors }),
}

const createOrderAction = {
  do: (params) => createAction(CREATE_ORDER.DO, params),
  success: () => createAction(CREATE_ORDER.SUCCESS),
  failed: (errors) => createAction(CREATE_ORDER.FAILED, { errors }),
}

const copyOrderAction = {
  do: (params) => createAction(COPY_ORDER.DO, params),
  success: () => createAction(COPY_ORDER.SUCCESS),
  failed: (errors) => createAction(COPY_ORDER.FAILED, { errors }),
}

const getOrderByIdAction = {
  do: (params) => createAction(GET_ORDER_BY_ID.DO, params),
  success: (data) => createAction(GET_ORDER_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_ORDER_BY_ID.FAILED, { errors }),
}

const deleteOrderAction = {
  do: () => createAction(DELETE_ORDER.DO),
  success: () => createAction(DELETE_ORDER.SUCCESS),
  failed: (errors) => createAction(DELETE_ORDER.FAILED, { errors }),
}

const updateOrderAction = {
  do: (params) => createAction(UPDATE_ORDER.DO, params),
  success: () => createAction(UPDATE_ORDER.SUCCESS),
  failed: (errors) => createAction(UPDATE_ORDER.FAILED, { errors }),
}

const getOrders =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getOrdersAction.do())
      const resp = await get('expense', `orders?page=${pageStart}&page_length=${pageLength}`, 'GET')
      dispatch(getOrdersAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting orders.'
      dispatch(getOrdersAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createOrder = (params) => async (dispatch) => {
  try {
    dispatch(createOrderAction.do())
    const resp = await post('expense', `orders`, params)
    dispatch(createOrderAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating order data.'
    dispatch(createOrderAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyOrder = (params) => async (dispatch) => {
  try {
    dispatch(copyOrderAction.do())
    const resp = await post('expense', `copy-order`, params)
    dispatch(copyOrderAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating order data.'
    dispatch(copyOrderAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getOrderById = (id) => async (dispatch) => {
  try {
    dispatch(getOrderByIdAction.do())
    if (id) {
      const resp = await get('expense', `orders/${id}`, 'GET')
      var orderData = resp
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      orderData.addresses = addresses
      orderData.categories = resp.transaction.tran_list
      orderData.items = resp.transaction.tran_item
      orderData.tags = resp.transaction.tran_tag
      dispatch(getOrderByIdAction.success(orderData))
    } else {
      await dispatch(createAction(INIT_SELECTED_ORDER))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting order data.'
    dispatch(getOrderByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch(deleteOrderAction.do())
    const resp = await destroy('expense', `orders/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteOrderAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating order data.'
    dispatch(deleteOrderAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateOrder = (id, params) => async (dispatch) => {
  try {
    dispatch(updateOrderAction.do())
    const resp = await put('expense', `orders/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateOrderAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating order data.'
    dispatch(updateOrderAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const removeAttachment = (id) => async (dispatch) => {
  try {
    await destroy('expense', `orders/${id}/remove-attachment`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting invoice attachment.'
    dispatch(showErrorToastAction(error))
  }
}

const generateOrderNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-purchaseorder-number', 'GET')
    return resp
  } catch (e) {}
}

const getOrderPDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await download('expense', `get-order-pdf/${id}`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting bill pdf.'
    return dispatch(showErrorToastAction(error))
  }
}

export {
  getOrdersAction,
  createOrderAction,
  copyOrderAction,
  getOrderByIdAction,
  deleteOrderAction,
  updateOrderAction,
  getOrders,
  createOrder,
  copyOrder,
  getOrderById,
  deleteOrder,
  updateOrder,
  removeAttachment,
  generateOrderNumber,
  getOrderPDFUrl,
}
