import { fromJS } from 'immutable'
import { NULL } from 'sass'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_ORDER,
  COPY_ORDER,
  DELETE_ORDER,
  GET_ORDER_BY_ID,
  GET_ORDERS,
  UPDATE_ORDER,
  INIT_SELECTED_ORDER,
} from '../../actions/expense/transactionOrder'

const EMPTY_ORDER = {
  id: '',
  contact_id: '',
  email: '',
  mailing_address: '',
  ship_to: '',
  purchase_order_date: '',
  shipping_address: '',
  ship_via: '',
  ref_no: '',
  permit_no: '',
  tags: '',
  total: '',
  vendor_message: '',
  memo: '',
  categories: [],
  items: [],
}

const DEFAULT_ORDER = {
  id: '',
  contact_id: '',
  email: '',
  mailing_address: '',
  ship_to: '',
  purchase_order_date: '',
  shipping_address: '',
  ship_via: '',
  ref_no: '',
  permit_no: '',
  tags: '',
  total: '',
  vendor_message: '',
  memo: '',
  categories: [],
  items: [],
}

const CREATE_REQUEST_ORDER = {
  id: '',
  contact_id: '',
  email: '',
  mailing_address: '',
  ship_to: '',
  purchase_order_date: '',
  shipping_address: '',
  ship_via: '',
  ref_no: '',
  permit_no: '',
  payee: '',
  tags: '',
  total: '',
  vendor_message: '',
  memo: '',
  transaction_list: [],
  transaction_item: [],
  transaction_tag: [],
  attachment: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  orders: [],
  emptyOrder: EMPTY_ORDER,
  selectedOrder: DEFAULT_ORDER,
  createRequestOrder: CREATE_REQUEST_ORDER,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setOrders = (data) => (state) => state.setIn(['orders'], data)

const setEmptyOrder = (data) => (state) => state.setIn(['emptyOrder'], data)

const setSelectedOrder = (data) => (state) => state.setIn(['selectedOrder'], data)

const setCreateRequestOrder = (data) => (state) => state.setIn(['createRequestOrder'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function transactionOrderReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_ORDERS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_ORDERS.SUCCESS:
      return mutate([resetStatus(), setOrders(action.payload)])
    case GET_ORDERS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setOrders([])])
    case CREATE_ORDER.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedOrder(DEFAULT_ORDER),
        setEmptyOrder(EMPTY_ORDER),
      ])
    case CREATE_ORDER.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_ORDER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case COPY_ORDER.DO:
      return mutate([resetStatusSetSaving()])
    case COPY_ORDER.SUCCESS:
      return mutate([resetStatus()])
    case COPY_ORDER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_ORDER.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_ORDER.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_ORDER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_ORDER.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_ORDER.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_ORDER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_ORDER_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_ORDER_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedOrder(action.payload)])
    case GET_ORDER_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedOrder(DEFAULT_ORDER)])
    case INIT_SELECTED_ORDER:
      return mutate([
        resetStatus(),
        setCreateRequestOrder(CREATE_REQUEST_ORDER),
        setSelectedOrder(DEFAULT_ORDER),
        setEmptyOrder(EMPTY_ORDER),
      ])
    default:
      return state
  }
}

export default transactionOrderReducer
