import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  GET_HSN_TAX_RATES,
  CREATE_HSN_TAX_RATES,
  UPDATE_HSN_TAX_RATES,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
  GET_HSN_TAX_RATES_BY_ID,
  INIT_SELECTED_HSN_TAX_RATES,
  GET_TAX_RATES_LIST,
} from '../../actions/sales/salesHsnTaxMapping'

export const DEFAULT_HSN_TAX = {
  tax_rates: [],
  hsn_code_master_id: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  selectedHsn: DEFAULT_HSN_TAX,
  hsnCodes: [],
  taxRatesList: [],
  paginationParams: {
    page: 1,
    sizePerPage: 20,
    totalSize: 0,
  },
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setHsnCodes = (data) => (state) => state.setIn(['hsnCodes'], data)
const setSelectedHsnCode = (data) => (state) => state.setIn(['selectedHsn'], data)
const setTaxRatesList = (data) => (state) => state.setIn(['taxRatesList'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)

function salesHsnTaxMapping(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_HSN_TAX_RATES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_HSN_TAX_RATES.SUCCESS:
      return mutate([resetStatus(), setHsnCodes(action.payload)])
    case GET_HSN_TAX_RATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setHsnCodes([])])
    case CREATE_HSN_TAX_RATES.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_HSN_TAX_RATES.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_HSN_TAX_RATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_HSN_TAX_RATES.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_HSN_TAX_RATES.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_HSN_TAX_RATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_HSN_TAX_RATES_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_HSN_TAX_RATES_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedHsnCode(action.payload)])
    case GET_HSN_TAX_RATES_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedHsnCode(DEFAULT_HSN_TAX)])
    case INIT_SELECTED_HSN_TAX_RATES:
      return mutate([resetStatus(), setSelectedHsnCode(action.payload || DEFAULT_HSN_TAX)])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    case GET_TAX_RATES_LIST.DO:
      return mutate([resetStatusSetLoading()])
    case GET_TAX_RATES_LIST.SUCCESS:
      return mutate([resetStatus(), setTaxRatesList(action.payload)])
    case GET_TAX_RATES_LIST.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTaxRatesList([])])
    default:
      return state
  }
}
export default salesHsnTaxMapping
