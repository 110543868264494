import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  GET_BANKING_PROVIDERS,
  GET_FASTLINK_PARAMS,
  GET_BANK_ACCOUNTS,
  GET_ACCOUNT_TRANSACTIONS,
  CREATE_ACCOUNT_TRANSACTION,
} from '../../actions/banking/bankingAccount'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  providers: [],
  fastLinkParams: {},
  accounts: [],
  transactions: [],
  expenseCategories: [],
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setProviders = (data) => (state) => state.setIn(['providers'], data)
const setFastLinkParams = (data) => (state) => state.setIn(['fastLinkParams'], data)
const setBankAccounts = (data) => (state) => state.setIn(['accounts'], data)
const setAccountTransactions = (data) => (state) => state.setIn(['transactions'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function bankingAccountReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_BANKING_PROVIDERS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BANKING_PROVIDERS.SUCCESS:
      return mutate([resetStatus(), setProviders(action.payload)])
    case GET_BANKING_PROVIDERS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setProviders([])])
    case GET_FASTLINK_PARAMS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_FASTLINK_PARAMS.SUCCESS:
      return mutate([resetStatus(), setFastLinkParams(action.payload)])
    case GET_FASTLINK_PARAMS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setFastLinkParams({})])
    case GET_BANK_ACCOUNTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BANK_ACCOUNTS.SUCCESS:
      return mutate([resetStatus(), setBankAccounts(action.payload)])
    case GET_BANK_ACCOUNTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setBankAccounts([])])
    case GET_ACCOUNT_TRANSACTIONS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_ACCOUNT_TRANSACTIONS.SUCCESS:
      return mutate([resetStatus(), setAccountTransactions(action.payload)])
    case GET_ACCOUNT_TRANSACTIONS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setAccountTransactions([])])
    case CREATE_ACCOUNT_TRANSACTION.DO:
      return mutate([resetStatusSetLoading()])
    case CREATE_ACCOUNT_TRANSACTION.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_ACCOUNT_TRANSACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    default:
      return state
  }
}

export default bankingAccountReducer
