import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  GET_RECONCILE_LIST,
  SHOW_RECONCILE,
  SET_RECONCILE_PARAMS,
  DO_RECONCILE,
  DO_RECONCILE_LATER,
  GET_RECONCILE_INFO,
} from '../../actions/banking/bankingReconcile'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  reconciles: [],
  showReconcile: false,
  reconcileParams: {},
  lastReconciledAt: null,
  beginningBalance: 0,
  endingBalance: 0,
})
const resetStatus = () => (state) => state.setIn(['status'], DEFAULT_STATUS)
const setReconciles = (data) => (state) => state.setIn(['reconciles'], data)
const setLastReconciledAt = (data) => (state) => state.setIn(['lastReconciledAt'], data)
const setBeginningBalance = (data) => (state) => state.setIn(['beginningBalance'], data)
const setEndingBalance = (data) => (state) => state.setIn(['endingBalance'], data)
const setShowReconcile = (showReconcile) => (state) => state.setIn(['showReconcile'], showReconcile)

const setReconcileParams = (reconcileParams) => (state) => {
  state.setIn(['reconcileParams'], reconcileParams)
}
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function bankingReconcileReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_RECONCILE_LIST.DO:
      return mutate([resetStatusSetLoading(), setReconciles([])])
    case GET_RECONCILE_LIST.SUCCESS:
      return mutate([resetStatus(), setReconciles(action.payload)])
    case GET_RECONCILE_LIST.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setReconciles([])])
    case GET_RECONCILE_INFO.DO:
      return mutate([
        resetStatusSetLoading(),
        setLastReconciledAt(null),
        setBeginningBalance(null),
        setEndingBalance(null),
      ])
    case GET_RECONCILE_INFO.SUCCESS:
      const data = action.payload
      return mutate([
        resetStatusSetLoading(),
        setLastReconciledAt(data.last_reconciled_at),
        setBeginningBalance(data.beginning_balance),
        setEndingBalance(data.ending_balance),
      ])
    case GET_RECONCILE_INFO.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setLastReconciledAt(null),
        setBeginningBalance(0),
        setEndingBalance(0),
      ])
    case DO_RECONCILE.DO:
      return state
    case DO_RECONCILE.SUCCESS:
      return mutate([resetStatus()])
    case DO_RECONCILE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DO_RECONCILE_LATER.DO:
      return state
    case DO_RECONCILE_LATER.SUCCESS:
      return mutate([resetStatus()])
    case DO_RECONCILE_LATER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case SHOW_RECONCILE:
      return mutate([resetStatus(), setShowReconcile(action.payload)])
    case SET_RECONCILE_PARAMS:
      return mutate([resetStatus(), setReconcileParams(action.payload)])
    default:
      return state
  }
}
export default bankingReconcileReducer
