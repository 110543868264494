import { fromJS } from 'immutable'
import { NULL } from 'sass'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_SUPPLIER,
  DELETE_SUPPLIER,
  GET_SUPPLIER_BY_ID,
  GET_SUPPLIERS,
  UPDATE_SUPPLIER,
  INIT_SELECTED_SUPPLIER,
  GET_SUPPLIER_TERMS,
  GET_TRANSACTION_COUNT,
  SET_PAGINATION_PARAMS,
  GET_EXPENSE_CHART,
} from '../../actions/expense/expenseSupplier'
import { generateGoogleID } from '../../../utils/expense'

// const EMPTY_SUPPLIER = {
//   id: '',
//   add_infos: [],
//   address: [],
//   attachments: [],
//   display_name: '',
//   email: '',
//   first_name: '',
//   last_name: '',
//   middle_name: '',
//   mobile_number: '',
//   name_on_checks: '',
//   notes: '',
//   phone_number: '',
//   tax_rates: [],
//   title: '',
//   type: 'vendor',
//   terms: [],
// }

// const DEFAULT_SUPPLIER = {
//   id: '',
//   add_infos: [],
//   address: [],
//   attachments: [],
//   display_name: '',
//   email: '',
//   first_name: '',
//   last_name: '',
//   middle_name: '',
//   mobile_number: '',
//   name_on_checks: '',
//   notes: '',
//   phone_number: '',
//   tax_rates: [],
//   title: '',
//   type: 'vendor',
//   terms: [],
// }

const CREATE_REQUEST_SUPPLIER = {
  addresses: [
    {
      administrative_area_level_1: '',
      administrative_area_level_2: '',
      country: '',
      formatted_address: '',
      google_place_id: generateGoogleID(),
      locality: 'Empty',
      postal_code: '',
      route: 'Empty',
      street_number: '',
      latitude: 0.0,
      longitude: 0.0,
      type: 'location',
    },
  ],
}

export const EMPTY_SUPPLIER = {
  contact_infos: {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    name_on_checks: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    type: 'vendor',
  },
  note: '',
  additional_infos: {
    expense_billing_rate: 0,
    expense_cost_rate: 0,
    ssn: null,
    suffix: null,
    fax: null,
    website: null,
    other: null,
    is_1099_track: false,
    expense_category: null,
    term_id: null,
    account_no: null,
    opening_balance: null,
    as_of_balance: null,
  },
  attachments: [],
  addresses: [],
  addresse: {
    location: {
      administrative_area_level_1: '',
      administrative_area_level_2: '',
      country: '',
      formatted_address: '',
      google_place_id: generateGoogleID(),
      locality: 'Empty',
      postal_code: '',
      route: 'Empty',
      street_number: '',
      latitude: 0.0,
      longitude: 0.0,
      type: 'location',
    },
  },
}

export const DEFAULT_SUPPLIER = {
  contact_infos: {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    name_on_checks: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    type: 'vendor',
  },
  note: '',
  additional_infos: {
    expense_billing_rate: 0,
    expense_cost_rate: 0,
    ssn: null,
    suffix: null,
    fax: null,
    website: null,
    other: null,
    is_1099_track: false,
    expense_category: null,
    term_id: null,
    account_no: null,
    opening_balance: null,
    as_of_balance: null,
    gst_treatment: 'Unregistered Business',
    tax_number: '',
  },
  attachments: [],
  addresses: [],
  address: {
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    country: '',
    formatted_address: '',
    google_place_id: generateGoogleID(),
    locality: 'Empty',
    postal_code: '',
    route: 'Empty',
    street_number: '',
    latitude: 0.0,
    longitude: 0.0,
    type: 'location',
  },
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  suppliers: [],
  emptySupplier: EMPTY_SUPPLIER,
  selectedSupplier: DEFAULT_SUPPLIER,
  createRequestSupplier: CREATE_REQUEST_SUPPLIER,
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.setIn(['status'], DEFAULT_STATUS)
const setSuppliers = (data) => (state) => state.setIn(['suppliers'], data)

const getEmptySupplier = (data) => (state) => state.setIn(['emptySupplier'], data)

const setSelectedSupplier = (data) => (state) => state.setIn(['selectedSupplier'], data)

const setcreateRequestSupplier = (data) => (state) => state.setIn(['createRequestSupplier'], data)
const setCountsDetail = (data) => (state) => state.setIn(['count_details'], data)
const setTransectionChart = (data) => (state) => state.setIn(['transection_chart'], data)

const resetStatusSetLoading = () => (state) => state.setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) => state.setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) => state.setIn(['status', 'isDeleting'], true)

const setErrors = (errors) => (state) => state.setIn(['status', 'errors'], errors)
const setSupplierTerms = (data) => (state) => state.setIn(['terms'], data)
const setPaginationParams = (params) => (state) => state.mergeIn(['paginationParams'], params)
function expenseSupplierReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_SUPPLIERS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_SUPPLIERS.SUCCESS:
      return mutate([resetStatus(), setSuppliers(action.payload)])
    case GET_SUPPLIERS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSuppliers([])])
    case CREATE_SUPPLIER.DO:
      return mutate([resetStatusSetSaving(), getEmptySupplier(EMPTY_SUPPLIER)])
    case CREATE_SUPPLIER.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_SUPPLIER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_SUPPLIER.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_SUPPLIER.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_SUPPLIER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_SUPPLIER.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_SUPPLIER.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_SUPPLIER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_SUPPLIER_BY_ID.DO:
      return mutate([resetStatus()])
    case GET_SUPPLIER_BY_ID.SUCCESS:
      return mutate([setSelectedSupplier(action.payload), resetStatusSetLoading()])
    case GET_SUPPLIER_BY_ID.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedSupplier(DEFAULT_SUPPLIER),
      ])
    case INIT_SELECTED_SUPPLIER:
      return mutate([resetStatus(), setcreateRequestSupplier(CREATE_REQUEST_SUPPLIER)])
    case GET_SUPPLIER_TERMS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_SUPPLIER_TERMS.SUCCESS:
      return mutate([resetStatus(), setSupplierTerms(action.payload)])
    case GET_SUPPLIER_TERMS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSupplierTerms([])])
    case GET_TRANSACTION_COUNT.DO:
      return mutate([setCountsDetail([])])
    case GET_TRANSACTION_COUNT.SUCCESS:
      return mutate([resetStatus(), setCountsDetail(action.payload)])
    case GET_TRANSACTION_COUNT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCountsDetail([])])
    case SET_PAGINATION_PARAMS:
      return mutate([resetStatusSetLoading(), setPaginationParams(action.payload)])
    case GET_EXPENSE_CHART.DO:
      return mutate([resetStatusSetLoading(), setTransectionChart([])])
    case GET_EXPENSE_CHART.SUCCESS:
      return mutate([resetStatus(), setTransectionChart(action.payload)])
    case GET_EXPENSE_CHART.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTransectionChart([])])
    default:
      return state
  }
}

export default expenseSupplierReducer
