import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { transformForList as transformContact } from '../../../services/ContactService'
import { navigateToPage } from '../../../utils/route'

export const CREATE_EMPLOYEE = createTypes('ZOOM/SALES/CREATE_EMPLOYEE')
export const GET_EMPLOYEES = createTypes('ZOOM/SALES/GET_EMPLOYEES')
export const GET_EMPLOYEE_BY_ID = createTypes('ZOOM/SALES/GET_EMPLOYEE_BY_ID')
export const UPDATE_EMPLOYEE = createTypes('ZOOM/SALES/UPDATE_EMPLOYEE')
export const DELETE_EMPLOYEE = createTypes('ZOOM/SALES/DELETE_EMPLOYEE')
export const INIT_SELECTED_EMPLOYEE = 'ZOOM/SALES/INIT_SELECTED_EMPLOYEE'
export const SET_PAGINATION_PARAMS = 'ZOOM/SALES/SET_EMPLOYEE_PAGINATION_PARAMS'
export const setPaginationParams = (params) =>
  createAction(SET_PAGINATION_PARAMS, { payload: params })

const createEmployeeAction = {
  do: (params) => createAction(CREATE_EMPLOYEE.DO, params),
  success: () => createAction(CREATE_EMPLOYEE.SUCCESS),
  failed: (errors) => createAction(CREATE_EMPLOYEE.FAILED, { errors }),
}
const updateEmployeeAction = {
  do: (params) => createAction(UPDATE_EMPLOYEE.DO, params),
  success: () => createAction(UPDATE_EMPLOYEE.SUCCESS),
  failed: (errors) => createAction(UPDATE_EMPLOYEE.FAILED, { errors }),
}
const getEmployeesAction = {
  do: (params) => createAction(GET_EMPLOYEES.DO, params),
  success: (employees) => createAction(GET_EMPLOYEES.SUCCESS, { payload: employees }),
  failed: (errors) => createAction(GET_EMPLOYEES.FAILED, { payload: errors }),
}
const getEmployeeByIdAction = {
  do: (params) => createAction(GET_EMPLOYEE_BY_ID.DO, params),
  success: (data) => createAction(GET_EMPLOYEE_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_EMPLOYEE_BY_ID.FAILED, { errors }),
}
const deleteEmployeeAction = {
  do: () => createAction(DELETE_EMPLOYEE.DO),
  success: () => createAction(DELETE_EMPLOYEE.SUCCESS),
  failed: (errors) => createAction(DELETE_EMPLOYEE.FAILED, { errors }),
}

const getEmployees =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getEmployeesAction.do())
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=employee`,
        'GET',
      )
      dispatch(
        setPaginationParams({
          page: resp.current_page,
          sizePerPage: parseInt(resp.per_page),
          totalSize: resp.total,
        }),
      )
      dispatch(getEmployeesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting employees.'
      dispatch(getEmployeesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getEmployeeById = (id) => async (dispatch) => {
  try {
    dispatch(getEmployeeByIdAction.do())
    if (id) {
      const resp = await get('sales', `contacts/${id}`, 'GET')
      dispatch(getEmployeeByIdAction.success(transformContact(resp)))
    } else {
      await dispatch(createAction(INIT_SELECTED_EMPLOYEE))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting employee data.'
    dispatch(getEmployeeByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const createEmployee = (params) => async (dispatch) => {
  try {
    dispatch(createEmployeeAction.do())
    const resp = await post('sales', `contacts`, params)
    dispatch(createEmployeeAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
    navigateToPage('/sales/employees')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating employee data.'
    dispatch(createEmployeeAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const updateEmployee = (id, params) => async (dispatch) => {
  try {
    dispatch(updateEmployeeAction.do())
    const resp = await put('sales', `contacts/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateEmployeeAction.success(resp))
    // navigateToPage('/sales/employees')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating employee data.'
    dispatch(updateEmployeeAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteEmployee = (id) => async (dispatch) => {
  try {
    dispatch(deleteEmployeeAction.do())
    const resp = await destroy('sales', `contacts/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteEmployeeAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating employee data.'
    dispatch(deleteEmployeeAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
export {
  createEmployeeAction,
  updateEmployeeAction,
  getEmployeesAction,
  getEmployeeByIdAction,
  deleteEmployeeAction,
  getEmployees,
  getEmployeeById,
  createEmployee,
  updateEmployee,
  deleteEmployee,
}
