import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_SHIPPING_STATES,
  GET_TIMEZONES,
} from '../../actions/sales/salesCountryState'
const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  countries: [],
  states: [],
  shippingStates: [],
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setCountries = (data) => (state) => state.setIn(['countries'], data)
const setStates = (data) => (state) => state.setIn(['states'], data)
const setShippingStates = (data) => (state) => state.setIn(['shippingStates'], data)
const setTimezones = (data) => (state) => state.setIn(['timezones'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function salesCountryStateReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_COUNTRIES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_COUNTRIES.SUCCESS:
      return mutate([resetStatus(), setCountries(action.payload)])
    case GET_COUNTRIES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCountries([])])
    case GET_STATES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_STATES.SUCCESS:
      return mutate([resetStatus(), setStates(action.payload)])
    case GET_STATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setStates([])])
    case GET_TIMEZONES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_TIMEZONES.SUCCESS:
      return mutate([resetStatus(), setTimezones(action.payload)])
    case GET_TIMEZONES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTimezones([])])
    case GET_SHIPPING_STATES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_SHIPPING_STATES.SUCCESS:
      return mutate([resetStatus(), setShippingStates(action.payload)])
    case GET_SHIPPING_STATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setShippingStates([])])
    default:
      return state
  }
}

export default salesCountryStateReducer
