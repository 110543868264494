import { createAction, createTypes } from '../../../utils/actions'
import { destroy, get, post, put } from '../../../utils/request'
import { showErrorToastAction } from '../global'
import { selectTagGroupsData } from '../../selectors/expense/expenseTagGroup'
export const GET_TAG_GROUPS = createTypes('ZOOM/EXPENSE/GET_TAG_GROUPS')
export const CREATE_TAG_GROUP = createTypes('ZOOM/EXPENSE/CREATE_TAG_GROUP')
export const UPDATE_TAG_GROUP = createTypes('ZOOM/EXPENSE/UPDATE_TAG_GROUP')
export const DELETE_TAG_GROUP = createTypes('ZOOM/EXPENSE/DELETE_TAG_GROUP')

const getTagGroupsAction = {
  do: (params) => createAction(GET_TAG_GROUPS.DO, params),
  success: (tagGroups) => createAction(GET_TAG_GROUPS.SUCCESS, { payload: tagGroups }),
  failed: (errors) => createAction(GET_TAG_GROUPS.FAILED, { payload: errors }),
}

const createTagGroupAction = {
  do: (params) => createAction(CREATE_TAG_GROUP.DO, params),
  success: () => createAction(CREATE_TAG_GROUP.SUCCESS),
  failed: (errors) => createAction(CREATE_TAG_GROUP.FAILED, { payload: errors }),
}

const updateTagGroupAction = {
  do: (params) => createAction(UPDATE_TAG_GROUP.DO, params),
  success: () => createAction(UPDATE_TAG_GROUP.SUCCESS),
  failed: (errors) => createAction(UPDATE_TAG_GROUP.FAILED, { payload: errors }),
}

const deleteTagGroupAction = {
  do: (params) => createAction(DELETE_TAG_GROUP.DO, params),
  success: () => createAction(DELETE_TAG_GROUP.SUCCESS),
  failed: (errors) => createAction(DELETE_TAG_GROUP.FAILED, { payload: errors }),
}

const getTagGroups =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getTagGroupsAction.do())
      const resp = await get(
        `expense`,
        `tag-group?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getTagGroupsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading tag groups.'
      dispatch(getTagGroupsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const createTagGroup = (params) => async (dispatch, getState) => {
  try {
    dispatch(createTagGroupAction.do())
    const resp = await post(`expense`, `tag-group`, params)
    dispatch(createTagGroupAction.success())
    const newTagsGroups = [...selectTagGroupsData(getState())]
    newTagsGroups.push(resp)
    dispatch(getTagGroupsAction.success(newTagsGroups))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating tag group.'
    dispatch(createTagGroupAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const updateTagGroup = (id, params) => async (dispatch, getState) => {
  try {
    dispatch(updateTagGroupAction.do())
    const resp = await put(`expense`, `tag-group/${id}`, params)
    dispatch(updateTagGroupAction.success(resp.data))
    const newTagsGroups = [...selectTagGroupsData(getState())].map((tagGroup) => {
      if (tagGroup.id === id) {
        tagGroup.name = params.name
        tagGroup.color_code = params.color_code
      }
      return tagGroup
    })
    dispatch(getTagGroupsAction.success(newTagsGroups))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating tag group.'
    dispatch(updateTagGroupAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const deleteTagGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteTagGroupAction.do())
    await destroy(`expense`, `tag-group/${id}`)
    const newTagsGroups = [...selectTagGroupsData(getState())].filter(
      (tagGroup) => tagGroup.id !== id,
    )
    dispatch(deleteTagGroupAction.success())
    dispatch(getTagGroupsAction.success(newTagsGroups))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating tag group.'
    dispatch(deleteTagGroupAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const createTag = (params) => async (dispatch, getState) => {
  try {
    dispatch(createTagGroupAction.do())
    const resp = await post(`expense`, `tags`, params)
    const newTagsGroups = [...selectTagGroupsData(getState())].filter((tagGroup) => {
      if (tagGroup.id === resp.tag_group_id) {
        tagGroup.tags.push(resp)
      }
      return tagGroup
    })
    dispatch(getTagGroupsAction.success(newTagsGroups))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating tag.'
    return dispatch(showErrorToastAction(error))
  }
}

const editTag = (id, params) => async (dispatch, getState) => {
  try {
    await put(`expense`, `tags/${id}`, params)
    const newTagsGroups = [...selectTagGroupsData(getState())].map((tagGroup) => {
      tagGroup.tags = tagGroup.tags.filter((tag) => tag.id !== id)
      if (tagGroup.id === params.tag_group_id) {
        tagGroup.tags.push(params)
      }
      return tagGroup
    })
    dispatch(getTagGroupsAction.success(newTagsGroups))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while edit the tag.'
    return dispatch(showErrorToastAction(error))
  }
}
export { getTagGroups, createTagGroup, updateTagGroup, deleteTagGroup, createTag, editTag }
