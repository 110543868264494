import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_CREDIT,
  GET_CREDIT,
  UPDATE_CREDIT,
  DELETE_CREDIT,
} from '../../actions/banking/bankingCreditCardCredit'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
})

const DEFAULT_CREDIT = {
  id: '',
  contact_id: '',
  coa_id: '',
  date: '',
  ref_no: '',
  permit_no: '',
  memo: '',
  categories: [],
  lines: [],
  tags: [],
  receive_payment: [],
}

const setSelectedCreditCardCredit = (data) => (state) =>
  state.setIn(['selectedCreditCardCredit'], data)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function bankingCreditReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CREDIT.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CREDIT.SUCCESS:
      return mutate([resetStatus(), setSelectedCreditCardCredit(action.payload)])
    case GET_CREDIT.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedCreditCardCredit(DEFAULT_CREDIT),
      ])

    case UPDATE_CREDIT.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])

    case CREATE_CREDIT.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])

    case DELETE_CREDIT.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])

    default:
      return state
  }
}
export default bankingCreditReducer
