import { CLink } from '@coreui/react-pro'
import styled from 'styled-components'

const ZLink = styled(CLink)`
  border-radius: 0 !important;
  min-width: 114px;
  text-align: center;
  cursor: pointer;
`
export default ZLink
