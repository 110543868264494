import React from 'react'
import { CCardHeader } from '@coreui/react-pro'
import styled from 'styled-components'

const Wrapper = styled.div`
  .card-header {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  p {
    margin-top: 20px;
    font-size: 13px;
  }
`

const ZCardHeader = (props) => {
  return (
    <Wrapper>
      <CCardHeader {...props}>{props.children}</CCardHeader>
    </Wrapper>
  )
}
export default ZCardHeader
