import { get } from '../utils/request'
import { inject } from 'dependency-injection-es6'
import ChartOfAccountService from './ChartOfAccountService'

class TransactionBillPayment {
  @inject(ChartOfAccountService) chartOfAccount: ChartOfAccountService
  billPaymentDataById = null

  // async getBillById(id) {
  //   const response = await get('expense', `bills/${id}`, 'GET')
  //   if (response.text) {
  //     return response
  //   } else {
  //     this.billPaymentDataById = response.bills[0]
  //     this.billPaymentDataById.bill_list = response.bill_list
  //     this.billPaymentDataById.bill_tag = response.bill_tag
  //     return this.billPaymentDataById
  //   }
  // }

  async createLedgerTransaction(payment_date, payment_account, totalAmount, memo, inputList) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    // payment account entry
    ledgerTransaction.chart_of_accounts.push(Number(payment_account))
    ledgerTransaction.credits.push(Number(0.0))
    ledgerTransaction.debits.push(Number(totalAmount))
    ledgerTransaction.memos.push(memo)
    // category account entry
    inputList.map((list) => {
      ledgerTransaction.chart_of_accounts.push(Number(list.category))
      ledgerTransaction.credits.push(Number(list.amount))
      ledgerTransaction.debits.push(Number(0.0))
      ledgerTransaction.memos.push(list.description)
    })
    return await this.chartOfAccount.storeLedgerTransaction(ledgerTransaction)
  }

  async createLedgerTransactionExpense(
    payment_date,
    payment_account,
    totalAmount,
    memo,
    inputList,
  ) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    // payment account entry
    ledgerTransaction.chart_of_accounts.push(Number(payment_account))
    ledgerTransaction.credits.push(Number(totalAmount))
    ledgerTransaction.debits.push(Number(0.0))
    ledgerTransaction.memos.push(memo)
    // category account entry
    inputList.map((list) => {
      let PayableID = list.PayableID ? list.PayableID : list.category
      ledgerTransaction.chart_of_accounts.push(Number(PayableID))
      ledgerTransaction.debits.push(Number(list.amount))
      ledgerTransaction.credits.push(Number(0.0))
      ledgerTransaction.memos.push(list.description)
    })
    return await this.chartOfAccount.storeLedgerTransaction(ledgerTransaction)
  }

  async deleteLedgerTransaction(ledger_transaction_id) {
    return await this.chartOfAccount.deleteLedgerTransaction(ledger_transaction_id)
  }
}
export default TransactionBillPayment
