import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { transformForList as transformContact } from '../../../services/ContactService'

export const CREATE_CUSTOMER = createTypes('ZOOM/SALES/CREATE_CUSTOMER')
export const GET_CUSTOMERS = createTypes('ZOOM/SALES/GET_CUSTOMERS')
export const GET_CUSTOMER_BY_ID = createTypes('ZOOM/SALES/GET_CUSTOMER_BY_ID')
export const UPDATE_CUSTOMER = createTypes('ZOOM/SALES/UPDATE_CUSTOMER')
export const DELETE_CUSTOMER = createTypes('ZOOM/SALES/DELETE_CUSTOMER')
export const INIT_SELECTED_CUSTOMER = 'ZOOM/SALES/INIT_SELECTED_CUSTOMER'
// export const SET_PAGINATION_PARAMS = 'ZOOM/SALES/INIT_SELECTED_CUSTOMER'
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_INVOICE_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_INVOICE_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_INVOICE_TOTAL_SIZE'

export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })
// export const setPaginationParams = (params) =>
//   createAction(SET_PAGINATION_PARAMS, { payload: params })

const createCustomerAction = {
  do: (params) => createAction(CREATE_CUSTOMER.DO, params),
  success: () => createAction(CREATE_CUSTOMER.SUCCESS),
  failed: (errors) => createAction(CREATE_CUSTOMER.FAILED, { errors }),
}
const updateCustomerAction = {
  do: (params) => createAction(UPDATE_CUSTOMER.DO, params),
  success: () => createAction(UPDATE_CUSTOMER.SUCCESS),
  failed: (errors) => createAction(UPDATE_CUSTOMER.FAILED, { errors }),
}
const getCustomersAction = {
  do: (params) => createAction(GET_CUSTOMERS.DO, params),
  success: (customers) => createAction(GET_CUSTOMERS.SUCCESS, { payload: customers }),
  failed: (errors) => createAction(GET_CUSTOMERS.FAILED, { payload: errors }),
}
const getCustomerByIdAction = {
  do: (params) => createAction(GET_CUSTOMER_BY_ID.DO, params),
  success: (data) => createAction(GET_CUSTOMER_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_CUSTOMER_BY_ID.FAILED, { errors }),
}
const deleteCustomerAction = {
  do: () => createAction(DELETE_CUSTOMER.DO),
  success: () => createAction(DELETE_CUSTOMER.SUCCESS),
  failed: (errors) => createAction(DELETE_CUSTOMER.FAILED, { errors }),
}

const getCustomers =
  (pageStart = 0, pageLength = 10, sort_by = '', sort_order = '', filter = {}) =>
  async (dispatch) => {
    try {
      const sort = JSON.stringify({ sort_by: sort_by, sort_order: sort_order })
      const filterStr = JSON.stringify(filter)
      dispatch(getCustomersAction.do())
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=customer&sort=${sort}&search=${filterStr}`,
        'GET',
      )
      dispatch(setCurrentPage(resp.current_page))
      dispatch(setSizePerPage(resp.per_page))
      dispatch(setTotalSize(resp.total))
      dispatch(getCustomersAction.success(resp.data))
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting customers.'
      dispatch(getCustomersAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getCustomerById = (id) => async (dispatch) => {
  try {
    dispatch(getCustomerByIdAction.do())
    if (id) {
      const resp = await get('sales', `contacts/${id}`, 'GET')
      dispatch(getCustomerByIdAction.success(transformContact(resp)))
      return resp
    } else {
      await dispatch(createAction(INIT_SELECTED_CUSTOMER))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting customer data.'
    dispatch(getCustomerByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const createCustomer = (params) => async (dispatch) => {
  try {
    dispatch(createCustomerAction.do())
    const resp = await post('sales', `contacts`, params)
    dispatch(createCustomerAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating customer data.'
    dispatch(createCustomerAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}
const updateCustomer = (id, params) => async (dispatch) => {
  try {
    dispatch(updateCustomerAction.do())
    const resp = await put('sales', `contacts/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateCustomerAction.success(resp))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating customer data.'
    dispatch(updateCustomerAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}
const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch(deleteCustomerAction.do())
    const resp = await destroy('sales', `contacts/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteCustomerAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating customer data.'
    dispatch(deleteCustomerAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getCustomerDataArray =
  (pageStart = 0, pageLength = 10, sort_by = '', sort_order = '', filter = {}) =>
  async (dispatch) => {
    try {
      const sort = JSON.stringify({ sort_by: sort_by, sort_order: sort_order })
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=customer&sort=${sort}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting customers.'
      return dispatch(showErrorToastAction(error))
    }
  }

const getDataArray =
  (pageStart = 0, pageLength = 10, sort_by = '', sort_order = '', filter = {}) =>
  async (dispatch) => {
    try {
      const sort = JSON.stringify({ sort_by: sort_by, sort_order: sort_order })
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&sort=${sort}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting customers.'
      return dispatch(showErrorToastAction(error))
    }
  }
export {
  createCustomerAction,
  updateCustomerAction,
  getCustomersAction,
  getCustomerByIdAction,
  deleteCustomerAction,
  getCustomers,
  getCustomerById,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerDataArray,
  getDataArray,
}
