import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutablejs'
import { LOCATION_CHANGE } from 'react-router-redux'
import { LOGOUT, VALIDATE_TOKEN } from 'views/user/actions'
import authReducer from 'views/user/reducer'
import { clearReducers } from 'utils/reducerUtils'
import globalReducer from './store/reducers/global'
import salesCustomerReducer from './store/reducers/sales/salesCustomer'
import salesCustomerTypeReducer from './store/reducers/sales/salesCustomerType'
import salesEmployeeReducer from './store/reducers/sales/salesEmployee'
import salesVendorReducer from './store/reducers/sales/salesVendor'
import salesProductReducer from './store/reducers/sales/salesProduct'
import salesInvoiceReducer from './store/reducers/sales/salesInvoice'
import salesProductCategoryReducer from './store/reducers/sales/salesProductCategory'
import salesTaxRateReducer from './store/reducers/sales/salesTaxRate'
import bankingAccountReducer from './store/reducers/banking/bankingAccount'
import settingsCompanyReducer from './store/reducers/settings/settingsCompany'
import settingsIntegrationReducer from './store/reducers/settings/settingsIntegration'
import expenseSupplierReducer from './store/reducers/expense/expenseSupplier'
import expenseTagGroupReducer from './store/reducers/expense/expenseTagGroup'
import transactionExpenseReducer from './store/reducers/expense/transactionExpense'
import transactionCheckReducer from './store/reducers/expense/transactionCheck'
import transactionCreditReducer from './store/reducers/expense/transactionCredit'
import transactionBillReducer from './store/reducers/expense/transactionBill'
import transactionBillPaymentReducer from './store/reducers/expense/transactionBillPayment'
import transactionOrderReducer from './store/reducers/expense/transactionOrder'
import subscriptionPlanReducer from './store/reducers/subscription/subscriptionPlan'
import coaAccountReducer from './store/reducers/coa/coaAccount'
import bankingDepositReducer from './store/reducers/banking/bankingDeposit'
import bankingCreditCardCreditReducer from './store/reducers/banking/bankingCreditCardCredit'
import bankingRuleReducer from './store/reducers/banking/bankingRule'
import salesPaymentMethodReducer from './store/reducers/sales/salesPaymentMethod'
import bankingCategoryReducer from './store/reducers/banking/bankingCategory'
import bankingReconcileReducer from './store/reducers/banking/bankingReconcile'
import trialReportReducer from './store/reducers/report/trialReport'
import profitLossReportReducer from './store/reducers/report/profitLossReport'
import invoiceListReducer from './store/reducers/report/invoiceList'
import accountingSettings from './store/reducers/account/accountingSettings'
import journalEntryReducer from './store/reducers/coa/journalEntry'
import AccountingSettingsAdvanced from './store/reducers/account/accountingSettingsAdvanced'
import salesCountryStateReducer from './store/reducers/sales/salesCountryState'
import salesHsnTaxMappingReducer from './store/reducers/sales/salesHsnTaxMapping'
import userProfile from './store/reducers/user-profile/userProfileSettings'
import SalesSetting from './store/reducers/account/salesSetting'
import ExpensesSetting from './store/reducers/account/expenseSetting'
import TaxAndComplianceSetting from './store/reducers/account/taxAndComplianceSetting'
import CustomizationSetting from './store/reducers/account/customizationSetting'
import bankingCreditReducer from './store/reducers/banking/bankingCreditCardCredit'
import salesInventoryAdjustmentReducer from 'store/reducers/sales/salesInventoryAdjustment'
import salesProductUnitsReducer from './store/reducers/sales/salesProductUnits'

const routeInitialState = fromJS({
  location: null,
})

const sidebarInitialState = {
  sidebarShow: true,
  asideShow: false,
}

const sidebarReducer = (state = sidebarInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      })
    default:
      return state
  }
}

export default function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    route: routeReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    global: globalReducer,
    salesCustomer: salesCustomerReducer,
    salesCustomerType: salesCustomerTypeReducer,
    salesEmployee: salesEmployeeReducer,
    salesVendor: salesVendorReducer,
    salesProduct: salesProductReducer,
    salesProductCategory: salesProductCategoryReducer,
    salesProductUnits: salesProductUnitsReducer,
    salesInventoryAdjustment: salesInventoryAdjustmentReducer,
    salesInvoice: salesInvoiceReducer,
    salesTaxRate: salesTaxRateReducer,
    salesPaymentMethod: salesPaymentMethodReducer,
    subscriptionPlan: subscriptionPlanReducer,
    bankingAccount: bankingAccountReducer,
    settingsCompany: settingsCompanyReducer,
    settingsIntegration: settingsIntegrationReducer,
    coaAccount: coaAccountReducer,
    bankingDeposit: bankingDepositReducer,
    bankingCreditCardCredit: bankingCreditCardCreditReducer,
    bankingRule: bankingRuleReducer,
    expenseSupplier: expenseSupplierReducer,
    expenseTagGroup: expenseTagGroupReducer,
    transactionExpense: transactionExpenseReducer,
    transactionCheck: transactionCheckReducer,
    transactionCredit: transactionCreditReducer,
    transactionBill: transactionBillReducer,
    transactionBillPayment: transactionBillPaymentReducer,
    transactionOrder: transactionOrderReducer,
    category: bankingCategoryReducer,
    bankingReconcile: bankingReconcileReducer,
    trialReport: trialReportReducer,
    profitLossReport: profitLossReportReducer,
    invoiceList: invoiceListReducer,
    settingsData: accountingSettings,
    journalEntry: journalEntryReducer,
    settingsDataAdv: AccountingSettingsAdvanced,
    salesSetting: SalesSetting,
    expensesSetting: ExpensesSetting,
    salesCountryState: salesCountryStateReducer,
    salesHsnTaxMapping: salesHsnTaxMappingReducer,
    userProfile: userProfile,
    taxAndComplianceSetting: TaxAndComplianceSetting,
    customizationSetting: CustomizationSetting,
    bankingCreditReducer: bankingCreditReducer,
    ...injectedReducers,
  })

  return (state, action) => {
    if (action.type === VALIDATE_TOKEN.DO) {
      return clearReducers(appReducer, state, action, {
        except: [[]],
      })
    }
    if (action.type === LOGOUT) {
      return clearReducers(appReducer, state, action, {
        except: [[]],
      })
    }
    return appReducer(state, action)
  }
}
