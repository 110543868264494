import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'
import moment from 'moment'

class AccountReceivableAging {
  async getAccountReceivableAging() {
    const asOfDate = moment().endOf('year').format('YYYY-MM-DD')
    const response = await get('report', `receivable-aging-report?as_of_date=${asOfDate}`, 'GET')
    return response
  }

  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default AccountReceivableAging
