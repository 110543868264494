import { get } from '../utils/request'

class TagService {
  tags = null
  tagGroups = null

  async getTags() {
    if (this.tags === null) {
      const response = await get('expense', 'tags?page_length=1000&page=0')
      this.tags = response.data

      const responseTagGroup = await get('expense', 'tag-group?page_length=1000&page=0')
      this.tagGroups = responseTagGroup.data

      for (var i = 0; i < this.tags.length; i++) {
        for (var j = 0; j < this.tagGroups.length; j++) {
          if (this.tags[i].tag_group_id == this.tagGroups[j].id) {
            this.tags[i].color_code = this.tagGroups[j].color_code
          }
        }
      }
    }
    return this.tags
  }
}
export default TagService
