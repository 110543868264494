import { fromJS } from 'immutable'
import { NULL } from 'sass'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_BILLPAYMENT,
  DELETE_BILLPAYMENT,
  VOID_BILLPAYMENT,
  GET_BILLPAYMENT_BY_ID,
  GET_BILLPAYMENTS,
  UPDATE_BILLPAYMENT,
  INIT_SELECTED_BILLPAYMENT,
} from '../../actions/expense/transactionBillPayment'

const EMPTY_BILLPAYMENT = {
  id: '',
  contact_id: '',
  payment_account: '',
  mailing_address: '',
  payment_date: '',
  ref_no: '',
  total: '',
  memo: '',
}

const DEFAULT_BILLPAYMENT = {
  id: '',
  contact_id: '',
  payment_account: '',
  mailing_address: '',
  payment_date: '',
  ref_no: '',
  total: '',
  memo: '',
}

const CREATE_REQUEST_BILLPAYMENT = {
  id: '',
  contact_id: '',
  payee: '',
  payment_account: '',
  ledger_transaction_id: '',
  mailing_address: '',
  bill_date: '',
  ref_no: '',
  total: '',
  applied_amount: '',
  credit_amount: '',
  memo: '',
  bills: [],
  attachment: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isVoiding: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  billpayments: [],
  emptyBillPayment: EMPTY_BILLPAYMENT,
  selectedBillPayment: DEFAULT_BILLPAYMENT,
  createRequestBillPayment: CREATE_REQUEST_BILLPAYMENT,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setBillPayments = (data) => (state) => state.setIn(['billpayments'], data)

const setEmptyBillPayment = (data) => (state) => state.setIn(['emptyBillPayment'], data)

const setSelectedBillPayment = (data) => (state) => state.setIn(['selectedBillPayment'], data)

const setCreateRequestBillPayment = (data) => (state) =>
  state.setIn(['createRequestBillPayment'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const resetStatusSetVoiding = () => (state) =>
  resetStatus()(state).setIn(['status', 'isVoiding'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function transactionBillPaymentReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_BILLPAYMENTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BILLPAYMENTS.SUCCESS:
      return mutate([resetStatus(), setBillPayments(action.payload)])
    case GET_BILLPAYMENTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setBillPayments([])])
    case CREATE_BILLPAYMENT.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedBillPayment(DEFAULT_BILLPAYMENT),
        setEmptyBillPayment(EMPTY_BILLPAYMENT),
      ])
    case CREATE_BILLPAYMENT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_BILLPAYMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_BILLPAYMENT.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_BILLPAYMENT.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_BILLPAYMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_BILLPAYMENT.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_BILLPAYMENT.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_BILLPAYMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case VOID_BILLPAYMENT.DO:
      return mutate([resetStatusSetVoiding()])
    case VOID_BILLPAYMENT.SUCCESS:
      return mutate([resetStatus()])
    case VOID_BILLPAYMENT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_BILLPAYMENT_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BILLPAYMENT_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedBillPayment(action.payload)])
    case GET_BILLPAYMENT_BY_ID.FAILED:
      return mutate([
        resetStatus(),
        setErrors(action.payload),
        setSelectedBillPayment(DEFAULT_BILLPAYMENT),
      ])
    case INIT_SELECTED_BILLPAYMENT:
      return mutate([
        resetStatus(),
        setCreateRequestBillPayment(CREATE_REQUEST_BILLPAYMENT),
        setSelectedBillPayment(DEFAULT_BILLPAYMENT),
        setEmptyBillPayment(EMPTY_BILLPAYMENT),
      ])
    default:
      return state
  }
}

export default transactionBillPaymentReducer
