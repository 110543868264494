import { get, post } from '../../../utils/request'
const { createAction, createTypes } = require('../../../utils/actions')
const { showErrorToastAction, showSuccessToastAction } = require('../global')

export const SET_EXPENSES_SETTING_ACTION = createTypes('ZOOM/SETTINGS/EXPENSE')

const setExpensesSettingAction = {
  do: () => createAction(SET_EXPENSES_SETTING_ACTION.DO),
  success: (payload) => createAction(SET_EXPENSES_SETTING_ACTION.SUCCESS, { payload }),
  failed: (errors) => createAction(SET_EXPENSES_SETTING_ACTION.FAILED, { errors }),
}

const getExpensesSetting = () => async (dispatch) => {
  try {
    dispatch(setExpensesSettingAction.do())
    const resp = await get(`user`, 'api/settings/expenses')
    dispatch(setExpensesSettingAction.success(resp?.data))
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while fetching expenses setting'
    dispatch(setExpensesSettingAction.failed(error))
  }
}

const setExpensesSetting = (postData) => async (dispatch) => {
  try {
    await post(`user`, 'api/settings/expenses', postData)
    dispatch(showSuccessToastAction('Expense Setting has been updated'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating expenses setting'
    return dispatch(showErrorToastAction(error))
  }
}

export { setExpensesSettingAction, setExpensesSetting, getExpensesSetting }
