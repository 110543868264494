import { fromJS } from 'immutable'
import { NULL } from 'sass'
import pipeMutators from '../../../utils/mutator'
import { GET_PROFIT_LOSS_REPORTS } from '../../actions/report/profitLossReport'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isVoiding: false,
}

const DEFAULT_REPORT = {
  selectMonth: '',
  startdate: '',
  enddate: '',
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
  selectedReport: DEFAULT_REPORT,
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)

const setReports = (data) => (state) => state.setIn(['profitLossreports'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function profitLossReportReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PROFIT_LOSS_REPORTS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PROFIT_LOSS_REPORTS.SUCCESS:
      return mutate([resetStatus(), setReports(action.payload)])
    case GET_PROFIT_LOSS_REPORTS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setReports([])])
    default:
      return state
  }
}

export default profitLossReportReducer
