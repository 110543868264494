import { createAction, createTypes } from '../../../utils/actions'
import { get } from '../../../utils/request'
import { showErrorToastAction } from '../global'

export const GET_TAX_RATES = createTypes('ZOOM/SALES/GET_TAX_RATES')
export const GET_TAX_EXEMPTIONS = createTypes('ZOOM/SALES/GET_TAX_EXEMPTIONS')

const getTaxRatesAction = {
  do: (params) => createAction(GET_TAX_RATES.DO, params),
  success: (taxRates) => createAction(GET_TAX_RATES.SUCCESS, { payload: taxRates }),
  failed: (errors) => createAction(GET_TAX_RATES.FAILED, { payload: errors }),
}
const getTaxExemptionsAction = {
  do: (params) => createAction(GET_TAX_EXEMPTIONS.DO, params),
  success: (taxExemptions) => createAction(GET_TAX_EXEMPTIONS.SUCCESS, { payload: taxExemptions }),
  failed: (errors) => createAction(GET_TAX_EXEMPTIONS.FAILED, { payload: errors }),
}
const getTaxRates =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getTaxRatesAction.do())
      const resp = await get(
        'sales',
        `tax-rates?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getTaxRatesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting tax rates.'
      dispatch(getTaxRatesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getTaxExemptions =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getTaxExemptionsAction.do())
      const resp = await get(
        'sales',
        `tax-exemptions?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getTaxExemptionsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting tax exemptions.'
      dispatch(getTaxExemptionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
export { getTaxRates, getTaxExemptions, getTaxRatesAction }
