import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { CREATE_DEPOSIT } from '../../actions/banking/bankingDeposit'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
}

const initialState = fromJS({
  status: DEFAULT_STATUS,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function bankingDepositReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case CREATE_DEPOSIT.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_DEPOSIT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_DEPOSIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    default:
      return state
  }
}
export default bankingDepositReducer
