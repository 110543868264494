import { createAction, createTypes } from '../../../utils/actions'
import { get } from '../../../utils/request'
import { showErrorToastAction } from '../global'

export const GET_CATEGORIES = createTypes('ZOOM/BANKING/GET_CATGORIES')
const getCategoriesAction = {
  do: (params) => createAction(GET_CATEGORIES.DO, params),
  success: (categories) => createAction(GET_CATEGORIES.SUCCESS, { payload: categories }),
  failed: (errors) => createAction(GET_CATEGORIES.FAILED, { payload: errors }),
}

const getCategories =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getCategoriesAction.do())
      const resp = await get(
        'banking',
        `categories?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getCategoriesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting categories.'
      dispatch(getCategoriesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
export { getCategories }
