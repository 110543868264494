import React from 'react'
import { CCardFooter } from '@coreui/react-pro'
import ZDivider from './ZDivider'
import styled from 'styled-components'

const Wrapper = styled.div`
  .card-footer {
    background-color: white !important;
    border-color: white !important;
  }
  p {
    margin-top: 20px;
    font-size: 13px;
  }
`

const ZCardFooter = (props) => {
  return (
    <Wrapper>
      <CCardFooter {...props}>
        <ZDivider />
        {props.children}
      </CCardFooter>
    </Wrapper>
  )
}
export default ZCardFooter
