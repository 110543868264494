import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put, download } from '../../../utils/request'

export const GET_BILLS = createTypes('ZOOM/TRANSACTION/GET_BILLS')
export const CREATE_BILL = createTypes('ZOOM/TRANSACTION/CREATE_BILL')
export const COPY_BILL = createTypes('ZOOM/TRANSACTION/COPY_BILL')
export const GET_BILL_BY_ID = createTypes('ZOOM/TRANSACTION/GET_BILL_BY_ID')
export const GET_UNPAID_BILL_BY_CONTACT_ID = createTypes(
  'ZOOM/TRANSACTION/GET_UNPAID_BILL_BY_CONTACT_ID',
)
export const DELETE_BILL = createTypes('ZOOM/TRANSACTION/DELETE_BILL')
export const UPDATE_BILL = createTypes('ZOOM/TRANSACTION/UPDATE_BILL')
export const INIT_SELECTED_BILL = 'ZOOM/TRANSACTION/INIT_SELECTED_BILL'

const getBillsAction = {
  do: (params) => createAction(GET_BILLS.DO, params),
  success: (bills) => createAction(GET_BILLS.SUCCESS, { payload: bills }),
  failed: (errors) => createAction(GET_BILLS.FAILED, { payload: errors }),
}

const createBillAction = {
  do: (params) => createAction(CREATE_BILL.DO, params),
  success: () => createAction(CREATE_BILL.SUCCESS),
  failed: (errors) => createAction(CREATE_BILL.FAILED, { errors }),
}

const copyBillAction = {
  do: (params) => createAction(COPY_BILL.DO, params),
  success: () => createAction(COPY_BILL.SUCCESS),
  failed: (errors) => createAction(COPY_BILL.FAILED, { errors }),
}

const getBillByIdAction = {
  do: (params) => createAction(GET_BILL_BY_ID.DO, params),
  success: (data) => createAction(GET_BILL_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_BILL_BY_ID.FAILED, { errors }),
}

const getUnpaidBillByContactIdAction = {
  do: (params) => createAction(GET_UNPAID_BILL_BY_CONTACT_ID.DO, params),
  success: (data) => createAction(GET_UNPAID_BILL_BY_CONTACT_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_UNPAID_BILL_BY_CONTACT_ID.FAILED, { errors }),
}

const deleteBillAction = {
  do: () => createAction(DELETE_BILL.DO),
  success: () => createAction(DELETE_BILL.SUCCESS),
  failed: (errors) => createAction(DELETE_BILL.FAILED, { errors }),
}

const updateBillAction = {
  do: (params) => createAction(UPDATE_BILL.DO, params),
  success: () => createAction(UPDATE_BILL.SUCCESS),
  failed: (errors) => createAction(UPDATE_BILL.FAILED, { errors }),
}

const getBills =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getBillsAction.do())
      const resp = await get('expense', `bills?page=${pageStart}&page_length=${pageLength}`, 'GET')
      dispatch(getBillsAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting bills.'
      dispatch(getBillsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createBill = (params) => async (dispatch) => {
  try {
    dispatch(createBillAction.do())
    const resp = await post('expense', `bills`, params)
    dispatch(createBillAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating bill data.'
    dispatch(createBillAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyBill = (params) => async (dispatch) => {
  try {
    dispatch(copyBillAction.do())
    const resp = await post('expense', `copy-bill`, params)
    dispatch(copyBillAction.success(resp))
    dispatch(showSuccessToastAction('Successfully copy.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while copy bill data.'
    dispatch(copyBillAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyToBill = (params, id) => async (dispatch) => {
  try {
    dispatch(copyBillAction.do())
    const resp = await post('expense', `copy-bill/${id}`, params)
    dispatch(copyBillAction.success(resp))
    dispatch(showSuccessToastAction('Successfully copy.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while copy bill data.'
    dispatch(copyBillAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getBillById = (id) => async (dispatch) => {
  try {
    dispatch(getBillByIdAction.do())
    if (id) {
      const resp = await get('expense', `bills/${id}`, 'GET')
      var billData = resp
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      billData.categories = resp.transaction.tran_list
      billData.items = resp.transaction.tran_item
      billData.tags = resp.transaction.tran_tag
      billData.attachment = resp?.attachment ? resp?.attachment : []
      dispatch(getBillByIdAction.success({ ...billData, addresses: addresses }))
    } else {
      await dispatch(createAction(INIT_SELECTED_BILL))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting bill data.'
    dispatch(getBillByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getUnpaidBillByContactId = (id) => async (dispatch) => {
  try {
    dispatch(getUnpaidBillByContactIdAction.do())
    if (id) {
      const resp = await get('expense', `get-bill/${id}`, 'GET')
      var unpaidBillData = {
        errors: '',
        data: resp,
      }
      dispatch(getUnpaidBillByContactIdAction.success(unpaidBillData))
    } else {
      await dispatch(createAction(INIT_SELECTED_BILL))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting bill data.'
    dispatch(getUnpaidBillByContactIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const deleteBill = (id) => async (dispatch) => {
  try {
    dispatch(deleteBillAction.do())
    const resp = await destroy('expense', `bills/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteBillAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating bill data.'
    dispatch(deleteBillAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateBill = (id, params) => async (dispatch) => {
  try {
    dispatch(updateBillAction.do())
    const resp = await put('expense', `bills/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateBillAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating bill data.'
    dispatch(updateBillAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const removeAttachment = (id) => async (dispatch) => {
  try {
    await destroy('expense', `bills/${id}/remove-attachment`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting invoice attachment.'
    dispatch(showErrorToastAction(error))
  }
}
const generateBillNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-bill-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest invoice number.'
    showErrorToastAction(error)
  }
}

const getBillPDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await download('expense', `expense/${id}/get-bill-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting bill pdf.'
    return dispatch(showErrorToastAction(error))
  }
}
export {
  getBillsAction,
  createBillAction,
  copyBillAction,
  getBillByIdAction,
  getUnpaidBillByContactIdAction,
  deleteBillAction,
  updateBillAction,
  getBills,
  createBill,
  copyBill,
  getBillById,
  getUnpaidBillByContactId,
  deleteBill,
  updateBill,
  removeAttachment,
  generateBillNumber,
  copyToBill,
  getBillPDFUrl,
}
