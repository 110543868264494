import { get, post } from '../../../utils/request'

const { createAction, createTypes } = require('../../../utils/actions')
const { showErrorToastAction, showSuccessToastAction } = require('../global')

export const SET_SALES_SETTING_ACTION = createTypes('ZOOM/SETTINGS/SALES')

const setSalesSettingAction = {
  do: () => createAction(SET_SALES_SETTING_ACTION.DO),
  success: (payload) => createAction(SET_SALES_SETTING_ACTION.SUCCESS, { payload }),
  failed: (errors) => createAction(SET_SALES_SETTING_ACTION.FAILED, { errors }),
}

const getSalesSetting = () => async (dispatch) => {
  try {
    dispatch(setSalesSettingAction.do())
    const resp = await get(`user`, 'api/settings/sales')
    dispatch(setSalesSettingAction.success(resp?.data))
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while fetching sales setting'
    dispatch(setSalesSettingAction.failed(error))
  }
}

const setSalesSetting = (postData) => async (dispatch) => {
  try {
    await post(`user`, 'api/settings/sales', postData)
    dispatch(showSuccessToastAction('Sales Setting has been updated'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating sales setting'
    return dispatch(showErrorToastAction(error))
  }
}

export { setSalesSettingAction, setSalesSetting, getSalesSetting }
