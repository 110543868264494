import pipeMutators from '../../utils/mutator'
import {
  SHOW_SUCCESS_TOAST,
  SHOW_ERROR_TOAST,
  SHOW_SPINNER,
  HIDE_SUCCESS_TOAST,
  HIDE_ERROR_TOAST,
  HIDE_SPINNER,
} from '../actions/global'
import { fromJS } from 'immutable'

const initialState = fromJS({
  showErrorToast: false,
  showSuccessToast: false,
  errors: null,
  message: null,
  spinner: {
    show: false,
    message: '',
  },
})
const resetErrorToast = () => (state) =>
  state.setIn(['errors'], null).setIn(['showErrorToast'], false)
const showErrorToast = (errors) => (state) => {
  state.setIn(['errors'], errors).setIn(['showErrorToast'], true)
}
const resetSuccessToast = () => (state) =>
  state.setIn(['message'], null).setIn(['showSuccessToast'], false)
const showSuccessToast = (errors) => (state) =>
  state.setIn(['message'], errors).setIn(['showSuccessToast'], true)
const resetSpinner = () => (state) =>
  state.setIn(['spinner', 'show'], false).setIn(['spinner', 'message'], '')
const showSpinner = (message) => (state) =>
  state.setIn(['spinner', 'show'], true).setIn(['spinner', 'message'], message)
function globalReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case SHOW_ERROR_TOAST:
      return mutate([resetErrorToast(), resetSuccessToast(), showErrorToast(action.errors)])
    case HIDE_ERROR_TOAST:
      return mutate([resetErrorToast()])
    case SHOW_SUCCESS_TOAST:
      return mutate([resetErrorToast(), resetSuccessToast(), showSuccessToast(action.message)])
    case HIDE_SUCCESS_TOAST:
      return mutate([resetSuccessToast()])
    case SHOW_SPINNER:
      return mutate([showSpinner(action.message)])
    case HIDE_SPINNER:
      return mutate([resetSpinner()])
    default:
      return state
  }
}

export default globalReducer
