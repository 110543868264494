import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'
import moment from 'moment'

class ProfitLossReport {
  async getProfitLoss(start_date, end_date) {
    const response = await get(
      'report',
      `profit-loss-report?start_date=${start_date}&end_date=${end_date}`,
      'GET',
    )
    return response
  }

  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default ProfitLossReport
