import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_CUSTOMER_TYPE,
  DELETE_CUSTOMER_TYPE,
  GET_CUSTOMER_TYPES,
  UPDATE_CUSTOMER_TYPE,
} from '../../actions/sales/salesCustomerType'
const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  customerTypes: [],
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setCustomerTypes = (data) => (state) => state.setIn(['customerTypes'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function salesCustomerReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CUSTOMER_TYPES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CUSTOMER_TYPES.SUCCESS:
      return mutate([resetStatus(), setCustomerTypes(action.payload)])
    case GET_CUSTOMER_TYPES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCustomerTypes([])])
    case CREATE_CUSTOMER_TYPE.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_CUSTOMER_TYPE.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_CUSTOMER_TYPE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_CUSTOMER_TYPE.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_CUSTOMER_TYPE.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_CUSTOMER_TYPE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_CUSTOMER_TYPE.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_CUSTOMER_TYPE.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_CUSTOMER_TYPE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    default:
      return state
  }
}

export default salesCustomerReducer
