/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import timeout from 'redux-effects-timeout'
import thunk from 'redux-thunk'
import { persistStore, autoRehydrate } from 'redux-persist-immutable'

import createReducer from './reducers'

export default function configureStore(initialState = {}) {
  const browserHistory = createBrowserHistory()
  const middlewares = [thunk, timeout(), routerMiddleware(browserHistory)]

  const enhancers = [applyMiddleware(...middlewares), autoRehydrate()]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose
  /* eslint-enable */

  return new Promise((resolve, reject) => {
    try {
      const store = createStore(
        createReducer(),
        fromJS(initialState),
        composeEnhancers(...enhancers),
      )

      persistStore(store, { blacklist: ['route', 'updateAvailable'] }, () => {
        resolve(store)
      })

      // Extensions
      store.injectedReducers = {} // Reducer registry

      // Make reducers hot reloadable, see http://mxs.is/googmo
      /* istanbul ignore next */
      if (module.hot) {
        module.hot.accept('./reducers', () => {
          import('./reducers').then((reducerModule) => {
            const createReducers = reducerModule.default
            const nextReducers = createReducers(store.injectedReducers)

            store.replaceReducer(nextReducers)
          })
        })
      }
    } catch (e) {
      reject(e)
    }
  })
}
