import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { getRulesData } from '../../selectors/banking/bankingRule'

export const CREATE_RULE = createTypes('ZOOM/BANKING/CREATE_RULE')
export const GET_RULES = createTypes('ZOOM/BANKING/GET_RULES')
export const GET_RULE_BY_ID = createTypes('ZOOM/BANKING/GET_RULE_BY_ID')
export const UPDATE_RULE = createTypes('ZOOM/BANKING/UPDATE_RULE')
export const DELETE_RULE = createTypes('ZOOM/BANKING/DELETE_RULE')
export const INIT_SELECTED_RULE = 'ZOOM/BANKING/INIT_SELECTED_RULE'

const createRuleAction = {
  do: (params) => createAction(CREATE_RULE.DO, params),
  success: () => createAction(CREATE_RULE.SUCCESS),
  failed: (errors) => createAction(CREATE_RULE.FAILED, { errors }),
}

const initRuleAction = (rule) => createAction(INIT_SELECTED_RULE, { payload: rule })
const getRulesAction = {
  do: (params) => createAction(GET_RULES.DO, params),
  success: (rules) => createAction(GET_RULES.SUCCESS, { payload: rules }),
  failed: (errors) => createAction(GET_RULES.FAILED, { payload: errors }),
}

const updateRuleAction = {
  do: (params) => createAction(UPDATE_RULE.DO, params),
  success: () => createAction(UPDATE_RULE.SUCCESS),
  failed: (errors) => createAction(UPDATE_RULE.FAILED, { payload: errors }),
}
const getRuleByIdAction = {
  do: (params) => createAction(GET_RULE_BY_ID.DO, params),
  success: (data) => createAction(GET_RULE_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_RULE_BY_ID.FAILED, { payload: errors }),
}
const deleteRuleAction = {
  do: () => createAction(DELETE_RULE.DO),
  success: () => createAction(DELETE_RULE.SUCCESS),
  failed: (errors) => createAction(DELETE_RULE.FAILED, { payload: errors }),
}
const getRules =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getRulesAction.do())
      const resp = await get(
        'banking',
        `banking-rules?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getRulesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting rules.'
      dispatch(getRulesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const createRule = async (params) => async (dispatch) => {
  return await new Promise(async (resolve, reject) => {
    try {
      dispatch(createRuleAction.do())
      const requestParams = { ...params }
      requestParams['bank_accounts'] = requestParams['bank_accounts'].map(
        (bankAccount) => bankAccount.value,
      )
      const response = await post('banking', 'banking-rules', requestParams)
      dispatch(getRules(0, 1000))
      dispatch(createRuleAction.success())
      dispatch(showSuccessToastAction('The rule successfully created.'))
      return resolve(response)
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while creating rule.'
      dispatch(createRuleAction.failed(error))
      dispatch(showErrorToastAction(error))
      return reject(e)
    }
  })
}

const getRuleById = (id) => async (dispatch) => {
  try {
    dispatch(getRuleByIdAction.do())
    if (id) {
      const resp = await get('banking', `banking-rules/${id}`, 'GET')
      dispatch(getRuleByIdAction.success(resp))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting rule data.'
    dispatch(getRuleByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const updateRule = (id, params) => async (dispatch) => {
  return await new Promise(async (resolve, reject) => {
    try {
      dispatch(updateRuleAction.do())
      const requestParams = { ...params }
      requestParams['bank_accounts'] = requestParams['bank_accounts'].map(
        (bankAccount) => bankAccount.id || bankAccount.value,
      )
      const response = await put('banking', `banking-rules/${id}`, requestParams)
      dispatch(getRules(0, 1000))
      dispatch(updateRuleAction.success())
      dispatch(showSuccessToastAction('The rule successfully updated.'))
      return resolve(response)
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while updating rule.'
      dispatch(updateRuleAction.failed(error))
      dispatch(showErrorToastAction(error))
      return reject(e)
    }
  })
}
const deleteRule = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteRuleAction.do())
    await destroy('banking', `banking-rules/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteRuleAction.success())
    const rules = [...getRulesData(getState())]
    const index = rules.findIndex((rule) => rule.id === id)
    rules.splice(index, 1)
    dispatch(getRulesAction.success(rules))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating rules.'
    dispatch(deleteRuleAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const initRule =
  (rule = null) =>
  (dispatch) => {
    if (rule) {
      rule['bank_accounts'] = rule['bank_accounts'].map((bankAccount) => {
        if (bankAccount.hasOwnProperty('id')) {
          return {
            label: bankAccount.name,
            value: bankAccount.id,
          }
        }
        return bankAccount
      })
      rule['tags'] = rule['tags'].map((tag) => {
        if (tag.hasOwnProperty('id')) {
          return {
            label: tag.name,
            value: tag.id,
          }
        }
        return tag
      })
    }
    dispatch(initRuleAction(rule))
  }

export {
  createRuleAction,
  initRuleAction,
  getRulesAction,
  updateRuleAction,
  getRuleByIdAction,
  deleteRuleAction,
  createRule,
  initRule,
  getRules,
  getRuleById,
  updateRule,
  deleteRule,
}
