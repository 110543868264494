import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put } from '../../../utils/request'
import { transformForList as transformContact } from '../../../services/ContactService'
import { navigateToPage } from '../../../utils/route'

export const CREATE_VENDOR = createTypes('ZOOM/SALES/CREATE_VENDOR')
export const GET_VENDORS = createTypes('ZOOM/SALES/GET_VENDORS')
export const GET_VENDOR_BY_ID = createTypes('ZOOM/SALES/GET_VENDOR_BY_ID')
export const UPDATE_VENDOR = createTypes('ZOOM/SALES/UPDATE_VENDOR')
export const DELETE_VENDOR = createTypes('ZOOM/SALES/DELETE_VENDOR')
export const INIT_SELECTED_VENDOR = 'ZOOM/SALES/INIT_SELECTED_VENDOR'
export const SET_PAGINATION_PARAMS = 'ZOOM/SALES/INIT_SELECTED_VENDOR'
export const setPaginationParams = (params) =>
  createAction(SET_PAGINATION_PARAMS, { payload: params })

const createVendorAction = {
  do: (params) => createAction(CREATE_VENDOR.DO, params),
  success: () => createAction(CREATE_VENDOR.SUCCESS),
  failed: (errors) => createAction(CREATE_VENDOR.FAILED, { errors }),
}
const updateVendorAction = {
  do: (params) => createAction(UPDATE_VENDOR.DO, params),
  success: () => createAction(UPDATE_VENDOR.SUCCESS),
  failed: (errors) => createAction(UPDATE_VENDOR.FAILED, { errors }),
}
const getVendorsAction = {
  do: (params) => createAction(GET_VENDORS.DO, params),
  success: (vendors) => createAction(GET_VENDORS.SUCCESS, { payload: vendors }),
  failed: (errors) => createAction(GET_VENDORS.FAILED, { payload: errors }),
}
const getVendorByIdAction = {
  do: (params) => createAction(GET_VENDOR_BY_ID.DO, params),
  success: (data) => createAction(GET_VENDOR_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_VENDOR_BY_ID.FAILED, { errors }),
}
const deleteVendorAction = {
  do: () => createAction(DELETE_VENDOR.DO),
  success: () => createAction(DELETE_VENDOR.SUCCESS),
  failed: (errors) => createAction(DELETE_VENDOR.FAILED, { errors }),
}

const getVendors =
  (pageStart = 0, pageLength = 10, sort_by = '', sort_order = '', filter = {}) =>
  async (dispatch) => {
    try {
      const sort = JSON.stringify({ sort_by: sort_by, sort_order: sort_order })
      const filterStr = JSON.stringify(filter)
      dispatch(getVendorsAction.do())
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=vendor&sort=${sort}&search=${filterStr}`,
        'GET',
      )
      dispatch(
        setPaginationParams({
          page: resp.current_page,
          sizePerPage: parseInt(resp.per_page),
          totalSize: resp.total,
        }),
      )
      dispatch(getVendorsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting vendors.'
      dispatch(getVendorsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getVendorById = (id) => async (dispatch) => {
  try {
    dispatch(getVendorByIdAction.do())
    if (id) {
      const resp = await get('sales', `contacts/${id}`, 'GET')
      dispatch(getVendorByIdAction.success(transformContact(resp)))
    } else {
      await dispatch(createAction(INIT_SELECTED_VENDOR))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting vendor data.'
    dispatch(getVendorByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const createVendor = (params) => async (dispatch) => {
  try {
    dispatch(createVendorAction.do())
    const resp = await post('sales', `contacts`, params)
    dispatch(createVendorAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating vendor data.'
    dispatch(createVendorAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const updateVendor = (id, params) => async (dispatch) => {
  try {
    dispatch(updateVendorAction.do())
    const resp = await put('sales', `contacts/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateVendorAction.success(resp))
    // navigateToPage('/sales/vendors')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating vendor data.'
    dispatch(updateVendorAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteVendor = (id) => async (dispatch) => {
  try {
    dispatch(deleteVendorAction.do())
    const resp = await destroy('sales', `contacts/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteVendorAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating vendor data.'
    dispatch(deleteVendorAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const getVendorDataArray =
  (pageStart = 0, pageLength = 10, sort_by = '', sort_order = '', filter = {}) =>
  async (dispatch) => {
    try {
      const sort = JSON.stringify({ sort_by: sort_by, sort_order: sort_order })
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=vendor&sort=${sort}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting vendors.'
      return dispatch(showErrorToastAction(error))
    }
  }
export {
  createVendorAction,
  updateVendorAction,
  getVendorsAction,
  getVendorByIdAction,
  deleteVendorAction,
  getVendors,
  getVendorById,
  createVendor,
  updateVendor,
  deleteVendor,
  getVendorDataArray,
}
