import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { SET_SALES_SETTING_ACTION } from 'store/actions/accounting/SalesSetting'

const DEFAULT_STATUS = {
  errors: null,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
})

const setSalesSetting = (data) => (state) => state.setIn(['salesSetting'], data)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)

function SalesSetting(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case SET_SALES_SETTING_ACTION.DO:
      return mutate([resetStatusSetLoading()])
    case SET_SALES_SETTING_ACTION.SUCCESS:
      return mutate([resetStatus(), setSalesSetting(action.payload)])
    case SET_SALES_SETTING_ACTION.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSalesSetting([])])
    default:
      return state
  }
}

export default SalesSetting
