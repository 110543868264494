import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import moment from 'moment'
import {
  CREATE_INVOICE,
  DELETE_INVOICE,
  GET_INVOICE_BY_ID,
  GET_INVOICE_TERMS,
  GET_INVOICES,
  GET_LATEST_INVOICE_NUMBER,
  INIT_SELECTED_INVOICE,
  SET_INVOICE_INSTALLMENTS,
  SET_INVOICE_LINES,
  UPDATE_INVOICE,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
  CREATE_RECEIVEPAYMENT,
  GET_INVOICE_COUNT,
  GET_SALES_CHART,
  GET_CUSTOMER_LEDGER,
} from '../../actions/sales/salesInvoice'

export const DEFAULT_INVOICE = {
  customer_id: null,
  customer_emails: [],
  attachments: [],
  payment_date: '',
  invoice_date: moment().format('YYYY-MM-DD'),
  due_date: '',
  shipping_date: '',
  term_id: '',
  invoice_no: '',
  reference_no: '',
  tracking_no: '',
  tags: [],
  addresses: {
    billing: null,
    location: null,
    shipping: null,
    ship_via: null,
  },
  lines: [],
  message_on_invoice: '',
  message_on_statement: '',
  is_recurring: false,
  installments: [],
}

const DEFAULT_INCOME_ACCOUNTS = []
const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const DEFAULT_TYPES = [
  { value: 'non_inventory', label: 'Non inventory' },
  { value: 'service', label: 'Service' },
]

const initialState = fromJS({
  status: DEFAULT_STATUS,
  selectedInvoice: DEFAULT_INVOICE,
  invoices: [],
  customer_ledger: [],
  types: DEFAULT_TYPES,
  incomeAccounts: DEFAULT_INCOME_ACCOUNTS,
  terms: [],
  latestInvoiceNumber: null,
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setInvoices = (data) => (state) => state.setIn(['invoices'], data)
const setCustomerLedger = (data) => (state) => state.setIn(['customer_ledger'], data)
const seCountsDetail = (data) => (state) => state.setIn(['count_details'], data)
const setSalesChart = (data) => (state) => state.setIn(['sales_chart_data'], data)
const setInvoiceTerms = (data) => (state) => state.setIn(['terms'], data)
const setLatestInvoiceNumber = (data) => (state) => state.setIn(['latestInvoiceNumber'], data)
const setSelectedInvoice = (data) => (state) => state.setIn(['selectedInvoice'], data)
const setInvoiceLines = (lines) => (state) => state.setIn(['selectedInvoice', 'lines'], lines)
const setInvoiceInstallments = (installments) => (state) =>
  state.setIn(['selectedInvoice', 'installments'], installments)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)
function salesInvoiceReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_INVOICES.DO:
      return mutate([resetStatusSetLoading(), setInvoices([])])
    case GET_INVOICES.SUCCESS:
      return mutate([resetStatus(), setInvoices(action.payload)])
    case GET_INVOICES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setInvoices([])])
    case CREATE_INVOICE.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_INVOICE.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_INVOICE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_INVOICE.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_INVOICE.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_INVOICE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_INVOICE.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_INVOICE.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_INVOICE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_INVOICE_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_INVOICE_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedInvoice(action.payload)])
    case GET_INVOICE_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedInvoice(DEFAULT_INVOICE)])
    case INIT_SELECTED_INVOICE:
      return mutate([resetStatus(), setSelectedInvoice(DEFAULT_INVOICE)])
    case GET_INVOICE_TERMS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_INVOICE_TERMS.SUCCESS:
      return mutate([resetStatus(), setInvoiceTerms(action.payload)])
    case GET_INVOICE_TERMS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setInvoiceTerms([])])
    case GET_LATEST_INVOICE_NUMBER.DO:
      return mutate([resetStatusSetLoading()])
    case GET_LATEST_INVOICE_NUMBER.SUCCESS:
      return mutate([resetStatus(), setLatestInvoiceNumber(action.payload)])
    case GET_LATEST_INVOICE_NUMBER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setLatestInvoiceNumber('00001')])
    case SET_INVOICE_LINES:
      return mutate([setInvoiceLines(action.payload)])
    case SET_INVOICE_INSTALLMENTS:
      return mutate([setInvoiceInstallments(action.payload)])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    case GET_INVOICE_COUNT.DO:
      return mutate([resetStatusSetLoading(), seCountsDetail([])])
    case GET_INVOICE_COUNT.SUCCESS:
      return mutate([resetStatus(), seCountsDetail(action.payload)])
    case GET_INVOICE_COUNT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), seCountsDetail([])])
    case GET_SALES_CHART.DO:
      return mutate([resetStatusSetLoading(), setSalesChart([])])
    case GET_SALES_CHART.SUCCESS:
      return mutate([resetStatus(), setSalesChart(action.payload)])
    case GET_SALES_CHART.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSalesChart([])])
    case GET_CUSTOMER_LEDGER.DO:
      return mutate([resetStatusSetLoading(), setCustomerLedger([])])
    case GET_CUSTOMER_LEDGER.SUCCESS:
      return mutate([resetStatus(), setCustomerLedger(action.payload)])
    case GET_CUSTOMER_LEDGER.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCustomerLedger([])])
    default:
      return state
  }
}
export default salesInvoiceReducer
