import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class TransactionOrder {
  orderDataById = null

  async getOrders() {
    const pageStart = 0
    const pageLength = 1000
    const response = await get(
      'expense',
      `orders?page=${pageStart}&page_length=${pageLength}`,
      'GET',
    )
    return response[0].data
  }

  async getOrderById(id) {
    const response = await get('expense', `orders/${id}`, 'GET')
    this.orderDataById = response.orders[0]
    this.orderDataById.order_list = response.order_list
    this.orderDataById.order_tag = response.order_tag
    this.orderDataById.order_item = response.order_item
    return this.orderDataById
  }
  async getOrderPDFURLById(id) {
    const response = await get('expense', `orders/${id}`, 'GET')
    if (response.orders[0].pdf_url) {
      var apiRootValue = `${apiRoot['expense']}`
      var pdf_url = apiRootValue.split('api/')[0] + response.orders[0].pdf_url
      return pdf_url
    } else {
      return 'empty'
    }
  }
}
export default TransactionOrder
