import { get } from '../utils/request'

class TransactionContactService {
  contacts = null
  allContacts = null
  allCustomerContacts = null

  async getContacts() {
    if (this.contacts === null) {
      const response = await get('sales', 'contacts?page_length=1000&page=0')
      this.contacts = response.data
    }
    return this.contacts
  }

  async getContactList() {
    if (this.allContacts === null) {
      const response = await get('expense', 'payee-list')
      this.allContacts = response ? response[0] : null
    }
    return this.allContacts
  }

  async getCustomerContactList() {
    if (this.allCustomerContacts === null) {
      const response = await get('expense', 'customer-list')
      this.allCustomerContacts = response ? response[0] : null
    }
    return this.allCustomerContacts
  }
  async getContactDetail(contact_id) {
    var contactDetail = ''
    var response = await get('sales', 'contacts?page_length=1000&page=0')
    response.data.map((contact) => {
      if (contact.id == contact_id) {
        contactDetail = contact
      }
    })
    return contactDetail
  }
  async getPayeeName(contact_id) {
    const getContact = await this.getContactList()
    var payee_name = ''
    getContact.map((con) => {
      if (con.id == contact_id) {
        payee_name = con.display_name
      }
    })
    return payee_name
  }
  async getPayeeAddress(contact_id) {
    const getContact = await this.getContactDetail(contact_id)
    var payee_address = getContact.addresses[0].formatted_address
    return payee_address
  }
}
export default TransactionContactService
