import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { GET_COMPANY_SETTINGS } from '../../actions/settings/settingsCompany'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isUpdated: false,
}
const DEFAULT_SETTINGS = {}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  settings: DEFAULT_SETTINGS,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setSettings = (data) => (state) => state.setIn(['settings'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function companySettingsReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_COMPANY_SETTINGS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_COMPANY_SETTINGS.SUCCESS:
      return mutate([resetStatus(), setSettings(action.payload)])
    case GET_COMPANY_SETTINGS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSettings(DEFAULT_SETTINGS)])
    default:
      return state
  }
}
export default companySettingsReducer
