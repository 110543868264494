export const selectSettingsIntegration = (state) => state.get('settingsIntegration')
export const getApiKeysData = (state) => {
  let apiKeys = selectSettingsIntegration(state).getIn(['apiKeys'])
  if (!Array.isArray(apiKeys)) return []
  return apiKeys
}
export const getStatusError = (state) =>
  selectSettingsIntegration(state).getIn(['status', 'errors'])
export const getSavingStatus = (state) =>
  selectSettingsIntegration(state).getIn(['status', 'isSaving'])
export const getLoadingStatus = (state) =>
  selectSettingsIntegration(state).getIn(['status', 'isLoading'])
export const getDeletingStatus = (state) =>
  selectSettingsIntegration(state).getIn(['status', 'isDeleting'])
