import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { GET_TAX_RATES, GET_TAX_EXEMPTIONS } from '../../actions/sales/salesTaxRate'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  taxRates: [],
  taxExemptions: [],
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setTaxRates = (data) => (state) => state.setIn(['taxRates'], data)
const setTaxExemptions = (data) => (state) => state.setIn(['taxExemptions'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function salesTaxRateReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_TAX_RATES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_TAX_RATES.SUCCESS:
      return mutate([resetStatus(), setTaxRates(action.payload)])
    case GET_TAX_RATES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTaxRates([])])
    case GET_TAX_EXEMPTIONS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_TAX_EXEMPTIONS.SUCCESS:
      return mutate([resetStatus(), setTaxExemptions(action.payload)])
    case GET_TAX_EXEMPTIONS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setTaxExemptions([])])
    default:
      return state
  }
}

export default salesTaxRateReducer
