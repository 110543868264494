import React from 'react'
import { CFormFeedback } from '@coreui/react-pro'

const ZFormFeedback = (props) => {
  return (
    <CFormFeedback {...props} style={{ display: 'block' }}>
      {props.children}
    </CFormFeedback>
  )
}
export default ZFormFeedback
