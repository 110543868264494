import React from 'react'
import { CCardGroup } from '@coreui/react-pro'
import styled from 'styled-components'

const Wrapper = styled.div`
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
const ZCardGroup = (props) => {
  return (
    <Wrapper>
      <CCardGroup {...props}>{props.children}</CCardGroup>
    </Wrapper>
  )
}
export default ZCardGroup
