import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_PLAN,
  DELETE_PLAN,
  GET_PLAN_BY_ID,
  GET_PLANS,
  UPDATE_PLAN,
  INIT_SELECTED_PLAN,
} from '../../actions/subscription/subscriptionPlan'

export const DEFAULT_PLAN = {
  name: '',
  code: '',
  invoice_name: '',
  description: '',
  show_on_invoices: false,
  show_on_quotes: false,
  billing_intervals: {
    type: 'month',
    amount: 0,
  },
  pricing: {
    type: 'flat_fee',
    currency: 'USD',
    installments: [
      {
        from_amount: 1,
        to_amount: 2,
        price: 0,
      },
    ],
  },
  trial: {
    type: 'day',
    amount: 0,
  },
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  plans: [],
  selectedPlan: DEFAULT_PLAN,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setPlans = (data) => (state) => state.setIn(['plans'], data)
const setSelectedPlan = (data) => (state) => state.setIn(['selectedPlan'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
function salesPlanReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PLANS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PLANS.SUCCESS:
      return mutate([resetStatus(), setPlans(action.payload)])
    case GET_PLANS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setPlans([])])
    case CREATE_PLAN.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_PLAN.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_PLAN.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_PLAN.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_PLAN.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_PLAN.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_PLAN.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_PLAN.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_PLAN.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_PLAN_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PLAN_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedPlan(action.payload)])
    case GET_PLAN_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedPlan(DEFAULT_PLAN)])
    case INIT_SELECTED_PLAN.DO:
      return mutate([resetStatusSetLoading()])
    case INIT_SELECTED_PLAN.SUCCESS:
      return mutate([resetStatus(), setSelectedPlan(DEFAULT_PLAN)])
    default:
      return state
  }
}

export default salesPlanReducer
