import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_VENDOR,
  DELETE_VENDOR,
  GET_VENDOR_BY_ID,
  GET_VENDORS,
  UPDATE_VENDOR,
  INIT_SELECTED_VENDOR,
  SET_PAGINATION_PARAMS,
} from '../../actions/sales/salesVendor'

const generateUniqueNo = () => {
  const timestamp = +new Date()
  return 'VEN' + timestamp.toString().slice(5, timestamp.length)
}
export const DEFAULT_VENDOR = {
  contact_infos: {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    name_on_checks: '',
    email: '',
    phone_number: '',
    mobile_number: '',
    type: 'vendor',
  },
  note: '',
  additional_infos: {
    expense_billing_rate: 0,
    expense_cost_rate: 0,
    ssn: null,
    suffix: null,
    fax: null,
    website: null,
    other: null,
    is_1099_track: false,
    expense_category: null,
    term_id: null,
    account_no: generateUniqueNo(),
    opening_balance: null,
    as_of_balance: null,
    gst_treatment: '',
    tax_number: '',
  },
  attachments: [],
  addresses: {},
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  vendors: [],
  selectedVendor: DEFAULT_VENDOR,
  paginationParams: {
    custom: true,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setVendors = (data) => (state) => state.setIn(['vendors'], data)
const setSelectedVendor = (data) => (state) => state.setIn(['selectedVendor'], data)
const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)
const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setPaginationParams = (params) => (state) => state.mergeIn(['paginationParams'], params)
function salesVendorReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_VENDORS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_VENDORS.SUCCESS:
      return mutate([resetStatus(), setVendors(action.payload)])
    case GET_VENDORS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setVendors([])])
    case CREATE_VENDOR.DO:
      return mutate([resetStatusSetSaving()])
    case CREATE_VENDOR.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_VENDOR.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_VENDOR.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_VENDOR.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_VENDOR.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_VENDOR.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_VENDOR.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_VENDOR.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_VENDOR_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_VENDOR_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedVendor(action.payload)])
    case GET_VENDOR_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedVendor(DEFAULT_VENDOR)])
    case SET_PAGINATION_PARAMS:
      return mutate([resetStatusSetLoading(), setPaginationParams(action.payload)])
    case INIT_SELECTED_VENDOR:
      return mutate([resetStatus(), setSelectedVendor(DEFAULT_VENDOR)])
    default:
      return state
  }
}

export default salesVendorReducer
